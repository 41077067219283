export const styles = {
  root: (theme) => ({
    backgroundColor: theme.palette.background.dark,
    minHeight: '90vh',
    height: '90vh',
    minWidth: '90vw',
    width: '90vw',
    [theme.breakpoints.up('xl')]: {
      minWidth: '73vw',
      width: '73vw',
    },
    display: 'flex',
    flexDirection: 'column',
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '95%',
    width: '100%',
  },
  reportNameInput: {
    marginBottom: '24px',
  },
  reportType: (theme, reportType, selectedReportType) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0 0 16px',
    border: `1px solid ${theme.palette.action.active}`,
    borderRadius: '4px',
    width: '100%',
    margin: 0,
    borderColor:
      selectedReportType === reportType
        ? theme.palette.action.selected
        : theme.palette.action.active,
    color:
      selectedReportType === reportType
        ? theme.palette.action.selected
        : theme.palette.action.active,
  }),
  reportOptionsGranularity: {
    marginTop: '16px',
  },
  reportOptionsContent: (theme) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    paddingInline: 3,
    paddingBlock: 2,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    height: '100%',
    overflow: 'auto',
    minHeight: '350px',
  }),
  reportOptionsSection: {
    padding: '16px',
  },
  reportOptionsSectionItem: {
    minWidth: '50%',
  },
  downloadButton: {
    width: 'fit-content',
  },
  downloadButtonIcon: {
    marginRight: '12px',
  },
  granularityGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  onePageGranularity: (theme) => ({
    display: 'flex',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: 2,
    borderRadius: '4px',
  }),
  disabledContent: (theme) => ({
    backgroundColor: theme.palette.text.disabled,
    pointerEvents: 'none',
  }),
  customReportBuilder: {
    position: 'relative',
    height: '100%',
  },
  circularProgress: {
    color: 'black',
    marginRight: '12px',
  },
  onePageTitle: {
    paddingTop: 3,
    paddingBottom: 1,
    fontWeight: 'bold',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'flex-end',
    width: '100%',
  },
  reportOptionsHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  reportOptionsCaption: (theme) => ({color: theme.palette.text.disabled}),
  reportOptionsTitle: {
    fontWeight: 'bold',
  },
};
