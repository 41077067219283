import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export const useRouteTracker = () => {
  const location = useLocation();
  const {page} = useSegmentAnalytics();

  useMemo(() => {
    // Call page function whenever the path changes
    page(undefined, location.pathname, {
      url: window.location.href,
      path: location.pathname,
    });
  }, [location, page]);
};
