import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import {Typography, useTheme} from '@mui/material';
import {LegendOrdinal, LegendItem, LegendLabel} from '@visx/legend';
import {scaleOrdinal} from '@visx/scale';
import BaseSkeleton from '@common/BaseSkeleton';
import {styles} from '@common/charts/BaseLegend.styles';
import {useChartTheme} from '@/hooks/charts/useChartTheme';

function BaseLegend({
  labels = [],
  onClick = () => {},
  disabled = [],
  isLoading,
  sx,
  showValues,
  height = 60,
}) {
  const theme = useTheme();
  const {
    theme: {colors},
  } = useChartTheme(labels.length);

  const colorScale = scaleOrdinal({
    domain: labels,
    range: labels.map((_, i) => colors[i % colors.length]),
  });

  if (isLoading) {
    return <BaseSkeleton height={height} sx={{...sx}} />;
  }

  return (
    <Box sx={{...styles.root(theme), ...sx}}>
      <LegendOrdinal scale={colorScale} labelFormat={(label) => label.label}>
        {(labels) => (
          <Box sx={styles.innerBox}>
            {labels.map((label) => (
              <LegendItem
                key={`legend-${label.datum.value}-${label.index}`}
                style={{
                  ...styles.legendItem,
                  justifyContent: labels.length < 3 ? 'center' : 'left',
                }}
                onClick={() => onClick(label.datum.value)}>
                <Box sx={styles.legendItemBox}>
                  <svg width={16} height={16}>
                    {label.datum.type === 'line' ? (
                      <line
                        x1="0"
                        y1="9"
                        x2="16"
                        y2="9"
                        stroke={label.datum.color ?? label.value}
                        strokeOpacity={
                          disabled.includes(label.datum.value) ? 0.2 : 1
                        }
                        strokeWidth={2}
                        strokeDasharray={label.datum.dashed ? '4 2' : undefined}
                      />
                    ) : (
                      <rect
                        fill={label.datum.color ?? label.value}
                        fillOpacity={
                          disabled.includes(label.datum.value) ? 0.2 : 1
                        }
                        rx={4}
                        width={16}
                        height={16}
                      />
                    )}
                  </svg>
                </Box>
                <LegendLabel
                  style={{
                    ...styles.legendLabel,
                    color: disabled.includes(label.datum.value)
                      ? theme.palette.text.disabled
                      : theme.palette.text.primary,
                  }}>
                  {showValues ? (
                    <Typography variant="caption" fontWeight="bold">
                      {`${label.datum.value} `}
                    </Typography>
                  ) : undefined}
                  <Typography variant="caption">{label.text}</Typography>
                </LegendLabel>
              </LegendItem>
            ))}
          </Box>
        )}
      </LegendOrdinal>
    </Box>
  );
}

BaseLegend.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sx: PropTypes.object,
  isLoading: PropTypes.bool,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  ).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  showValues: PropTypes.bool,
};

export default BaseLegend;
