import {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {CaretDown} from '@phosphor-icons/react';
import {Grid, Typography, useTheme} from '@mui/material';

import {
  getFilters,
  getIsGTMAvailable,
  getLocation,
  getSummaries,
  getThreatDataAvailable,
} from '@/selectors';
import {styles} from '@/components/analytics/AnalyticsSelector.styles';
import {ANALYTIC_TYPES_PANELS} from '@/components/analytics/constants';
import {BaseIconButton} from '@/components/common/buttons';
import BaseTooltip from '@/components/common/BaseTooltip';
import BaseChip from '@/components/common/buttons/BaseChip';

import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useTranslation} from '@/hooks/useTranslation';

function AnalyticsSelector({selected, components, onChange, granularity}) {
  const {track} = useSegmentAnalytics();
  const theme = useTheme();
  const {getI18N, locale} = useTranslation();

  const [open, setOpen] = useState(true);
  const {name: locationName} = useSelector(getLocation);
  const {countrySummary} = useSelector(getSummaries);
  const isThreatDataAvailable = useSelector(getThreatDataAvailable);
  const {isGTM} = useSelector(getFilters);
  const isGTMAvailable = useSelector(getIsGTMAvailable);

  const {panelType, topicSelectionText} = getI18N('threatAnalytics');
  const {gtmNotAvailable, gtmDisabled, selectionNotAvailable} =
    getI18N('tooltips');

  const handleClick = (panel) => {
    track('Analytics Panel Changed', {
      panel: panel.value,
      location: locationName,
    });
    onChange(panel.value);
  };

  const isOptionEnabled = useCallback(
    (panel) => {
      const hasGranularity = components.some(
        (component) =>
          component.analyticsType.includes(panel) &&
          component.granularity.includes(granularity),
      );

      const meetsDataRequirement = components.some(
        (component) =>
          component.analyticsType.includes(panel) &&
          component.dataRequirements.every((dataReq) => {
            if (dataReq === 'threats') {
              return isThreatDataAvailable;
            }
            if (dataReq === 'countries') {
              return Boolean(countrySummary);
            }
            return true;
          }),
      );

      const isForecastPanelEnabled = panel === 'forecast' ? isGTM : true;

      return hasGranularity && meetsDataRequirement && isForecastPanelEnabled;
    },

    [granularity, isThreatDataAvailable, countrySummary?.id, isGTM],
  );

  const getTooltip = useCallback(
    (panel) => {
      if (!isOptionEnabled(panel)) {
        if (panel === 'forecast') {
          if (!isGTMAvailable) return gtmNotAvailable;
          if (!isGTM) return gtmDisabled;
        }
        return selectionNotAvailable;
      }
      return '';
    },
    [isOptionEnabled, isGTM, isGTMAvailable, locale],
  );

  const options = useMemo(
    () =>
      ANALYTIC_TYPES_PANELS.map((option) => ({
        ...option,
        label: panelType?.[option.value] || option.label,
        disabled: !isOptionEnabled(option.value),
        tooltip: getTooltip(option.value),
      })),
    [granularity, locale, isOptionEnabled, getTooltip],
  );

  const firstEnabledOption = useMemo(
    () => options.find(({disabled}) => !disabled)?.value,
    [options],
  );

  const isSelectedDisabled = useMemo(
    () => options.some(({value, disabled}) => value === selected && disabled),
    [options, selected],
  );

  // If selected option is disabled, select the first enabled option
  useEffect(() => {
    if (isSelectedDisabled && firstEnabledOption) {
      onChange(firstEnabledOption);
    }
  }, [firstEnabledOption, isSelectedDisabled]);

  useEffect(() => {
    if (firstEnabledOption) {
      onChange(firstEnabledOption);
    }
  }, [firstEnabledOption, locationName]);

  return (
    <Grid container direction="column" sx={styles.root}>
      <Grid item sx={styles.header}>
        {open ? (
          <Grid container direction="row" wrap="wrap" sx={styles.chipContainer}>
            {options.map((panel, index) => (
              <BaseTooltip
                key={index}
                message={panel.tooltip}
                disabled={!panel.disabled}
                icon={false}
                disableInteractive>
                <Grid item>
                  <BaseChip
                    label={panel.label}
                    selected={selected === panel.value}
                    disabled={panel.disabled}
                    color="info"
                    selectedColor="info"
                    onClick={() => handleClick(panel)}
                  />
                </Grid>
              </BaseTooltip>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            sx={styles.selectionText}>
            {topicSelectionText}
          </Typography>
        )}
        <BaseIconButton
          icon={CaretDown}
          size={26}
          sx={styles.openButton}
          onClick={() => setOpen(!open)}
        />
      </Grid>
    </Grid>
  );
}

AnalyticsSelector.propTypes = {
  components: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  granularity: PropTypes.string.isRequired,
};

export default AnalyticsSelector;
