import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {
  setClickedLocation,
  saveLocationPopupStatus,
  setInspectingLocationStatus,
  setThreatAnalyticsArea,
} from '@store/modules/user/actions';

import {useApi} from '@hooks/api/useApi';
import {
  getUserLocationPopupStatus,
  getUserSavingLocationStatus,
  getUserInspectingLocationStatus,
  getClickedLocation,
  getThreatAnalyticsArea,
} from '@/selectors';
import {useMapboxGL} from '@/hooks/map/useMapboxGL';
import {useMapHandlers} from '@/hooks/map/useMapHandlers';
import {useSnackbar} from '@/hooks/useSnackbar';
import {useTranslation} from '@/hooks/useTranslation';

import SavedLocationModal from '@/components/saved-locations/SavedLocationModal';

function UserLocation() {
  const snackbarId = 'save-location-confirmation';
  const dispatch = useDispatch();
  const {clickSavedLocation, cancelSaveLocation, cancelInspectLocation} =
    useMapHandlers();
  const {getSavedLocations} = useApi();
  const {triggerMapRepaint} = useMapboxGL();
  const {showSnackbar, hideSnackbarById} = useSnackbar();
  const {getI18N} = useTranslation();

  const isPopupOpen = useSelector(getUserLocationPopupStatus);
  const savedLocation = useSelector(getClickedLocation);
  const isSaving = useSelector(getUserSavingLocationStatus);
  const isInspecting = useSelector(getUserInspectingLocationStatus);
  const analyticsArea = useSelector(getThreatAnalyticsArea);

  const [location, setLocation] = useState({});

  const {yesLabel, noLabel, saveLocationLabel} = getI18N('savedLocations');

  const handleCloseModal = () => {
    cancelInspectLocation();
    setLocation({});
  };

  const handleSuccess = (savedLocation) => {
    getSavedLocations.mutate();
    triggerMapRepaint('saved-locations-source');
    if (isSaving && savedLocation) {
      clickSavedLocation(savedLocation);
    } else if (isInspecting && savedLocation) {
      dispatch(setClickedLocation(savedLocation));
    } else {
      dispatch(setThreatAnalyticsArea({display: false}));
    }
  };

  useEffect(() => {
    if (isSaving && !isInspecting) {
      showSnackbar({
        id: snackbarId,
        message: saveLocationLabel,
        actions: [
          {
            label: noLabel,
            props: {
              variant: 'outlined',
              onClick: () => {
                cancelSaveLocation();
                hideSnackbarById(snackbarId);
              },
            },
          },
          {
            label: yesLabel,
            props: {
              onClick: () => {
                dispatch(saveLocationPopupStatus(true));
                dispatch(setInspectingLocationStatus(true));
                hideSnackbarById(snackbarId);
              },
            },
          },
        ],
        duration: 0,
      });
      setLocation(savedLocation);
    } else {
      hideSnackbarById(snackbarId);
    }
    if (isInspecting && !isSaving) {
      setLocation(savedLocation);
    }

    return () => {
      hideSnackbarById(snackbarId);
    };
  }, [isSaving, isInspecting, savedLocation]);

  useEffect(() => {
    if (isSaving) {
      setLocation({
        ...savedLocation,
        latitude: analyticsArea.latitude,
        longitude: analyticsArea.longitude,
        radiusMeters: analyticsArea.radius,
      });
    }
  }, [
    isSaving,
    savedLocation,
    analyticsArea.latitude,
    analyticsArea.longitude,
    analyticsArea.radius,
  ]);

  return (
    <SavedLocationModal
      open={isPopupOpen}
      defaultExpanded={!isSaving}
      savedLocation={location}
      handleClose={handleCloseModal}
      onSuccess={handleSuccess}
    />
  );
}

export default UserLocation;
