export const styles = {
  content: (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.light,
    border: 'none',
    borderRadius: '8px',
    paddingTop: 1,
    paddingBottom: 2,
    width: '100%',
    maxWidth: '500px',
    maxHeight: '90vh',
    overflow: 'auto',
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 1,
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  body: {
    paddingLeft: 2,
    paddingRight: 2,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 1,
  },
  icon: {
    backgroundColor: 'transparent',
  },
};
