import PropTypes from 'prop-types';
import {QueryClientProvider} from '@tanstack/react-query';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import store, {history} from '@store/index';
import NotificationProvider from '@/context/NotificationProvider';
import StyledThemeProvider from '@/styles/theme/StyledThemeProvider';
import AuthProvider from '@/context/AuthProvider';
import MapProvider from '@/context/MapProvider';
import ErrorPage from '@/components/views/ErrorPage';
import {useSentry} from '@/hooks/useSentry';
import {useHttpServices} from '@/hooks/useHttpServices';
import {useRouteTracker} from '@/hooks/useRouteTracker';

function Providers({children}) {
  const {queryClient} = useHttpServices();
  useRouteTracker();

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <NotificationProvider>
            <MapProvider>{children}</MapProvider>
          </NotificationProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

function HigherOrderProviders({children}) {
  const {ErrorBoundary} = useSentry();
  return (
    <Provider store={store}>
      <StyledThemeProvider>
        <ErrorBoundary fallback={<ErrorPage />}>
          <ConnectedRouter history={history}>
            <Providers>{children}</Providers>
          </ConnectedRouter>
        </ErrorBoundary>
      </StyledThemeProvider>
    </Provider>
  );
}

HigherOrderProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HigherOrderProviders;
