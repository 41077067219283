import {useMemo} from 'react';

import {
  API_CHANGE_PRIOR_PERIOD,
  API_CHANGE_RADIUS_PRIOR_PERIOD,
  API_CHANGE_PRIOR_YEAR,
  API_CHANGE_RADIUS_PRIOR_YEAR,
} from '@/hooks/api/constants';
import {useChart} from '@/hooks/api/useChart';
import {useMoment} from '@/hooks/useMoment';

export const useChangeTrend = ({
  id = 'change-detection',
  title = 'Change Detection',
  period = 'periodOverPeriod',
  granularity,
  aggregation,
  normalized = false,
  disabled = false,
}) => {
  const {moment} = useMoment();
  const pathPeriod =
    granularity !== 'radius'
      ? API_CHANGE_PRIOR_PERIOD
      : API_CHANGE_RADIUS_PRIOR_PERIOD;
  const pathYear =
    granularity !== 'radius'
      ? API_CHANGE_PRIOR_YEAR
      : API_CHANGE_RADIUS_PRIOR_YEAR;

  // Get monthly period over period change
  const {
    data: rawMonthlyPeriodData,
    error: errorMonthlyPeriod,
    isFetching: isFetchingMonthlyPeriod,
    downloadCSV: downloadCSVMonthlyPeriod,
    copyCSV: copyCSVMonthlyPeriod,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path: pathPeriod,
    exactDates: false,
    customParams: {
      timeAggregation: 'monthly',
    },
    disabled,
  });

  // Get quarterly period over period change
  const {
    data: rawQuarterlyPeriodData,
    error: errorQuarterlyPeriod,
    isFetching: isFetchingQuarterlyPeriod,
    downloadCSV: downloadCSVQuarterlyPeriod,
    copyCSV: copyCSVQuarterlyPeriod,
  } = useChart({
    id,
    title,
    granularity,
    path: pathPeriod,
    exactDates: false,
    customParams: {
      timeAggregation: 'quarterly',
    },
    disabled,
  });

  // Get monthly year over year change
  const {
    data: rawMonthlyYearData,
    error: errorMonthlyYear,
    isFetching: isFetchingMonthlyYear,
    downloadCSV: downloadCSVMonthlyYear,
    copyCSV: copyCSVMonthlyYear,
  } = useChart({
    id,
    title,
    granularity,
    path: pathYear,
    exactDates: false,
    customParams: {
      timeAggregation: 'monthly',
    },
    disabled,
  });

  // Get quarterly year over year change
  const {
    data: rawQuarterlyYearData,
    error: errorQuarterlyYear,
    isFetching: isFetchingQuarterlyYear,
    downloadCSV: downloadCSVQuarterlyYear,
    copyCSV: copyCSVQuarterlyYear,
  } = useChart({
    id,
    title,
    granularity,
    path: pathYear,
    exactDates: false,
    customParams: {
      timeAggregation: 'quarterly',
    },
    disabled,
  });

  // Format data for chart
  const formatData = (rawData) => {
    const data = rawData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }

    const getValue = (d) => {
      if (normalized === true) {
        return d.normalizedValue;
      }
      return d.percentage;
    };

    return data.map((data) => ({
      x: moment(data.date).local().toDate(),
      y: getValue(data),
      currentCount: data.currentCount,
      previousCount: data.previousCount,
    }));
  };

  // Get appropriate formatted data based on period and aggregation specified
  const formatted = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return formatData(rawQuarterlyYearData);
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return formatData(rawQuarterlyPeriodData);
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return formatData(rawMonthlyYearData);
    }
    return formatData(rawMonthlyPeriodData);
  }, [
    rawMonthlyPeriodData,
    rawQuarterlyPeriodData,
    rawMonthlyYearData,
    rawQuarterlyYearData,
    normalized,
    aggregation,
    period,
  ]);

  // Get appropriate downloadCSV based on period and aggregation specified
  const downloadCSV = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return downloadCSVQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return downloadCSVQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return downloadCSVMonthlyYear;
    }
    return downloadCSVMonthlyPeriod;
  }, [
    downloadCSVMonthlyPeriod,
    downloadCSVQuarterlyPeriod,
    downloadCSVMonthlyYear,
    downloadCSVQuarterlyYear,
  ]);

  // Get appropriate copy function based on period and aggregation specified
  const copyCSV = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return copyCSVQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return copyCSVQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return copyCSVMonthlyYear;
    }
    return copyCSVMonthlyPeriod;
  }, [
    copyCSVMonthlyPeriod,
    copyCSVQuarterlyPeriod,
    copyCSVMonthlyYear,
    copyCSVQuarterlyYear,
  ]);

  // Get appropriate isFetching based on period and aggregation specified
  const isFetching = useMemo(() => {
    if (aggregation === 'quarterly' && period === 'yearOverYear') {
      return isFetchingQuarterlyYear;
    }
    if (aggregation === 'quarterly' && period === 'periodOverPeriod') {
      return isFetchingQuarterlyPeriod;
    }
    if (aggregation === 'monthly' && period === 'yearOverYear') {
      return isFetchingMonthlyYear;
    }
    return isFetchingMonthlyPeriod;
  }, [
    isFetchingMonthlyPeriod,
    isFetchingQuarterlyPeriod,
    isFetchingMonthlyYear,
    isFetchingQuarterlyYear,
  ]);

  const yDomain = useMemo(() => {
    if (!formatted || formatted.length === 0) {
      return ['-2', '2'];
    }

    // Get min & max y values
    let yMin = Math.min(...formatted.map((d) => d.y));
    let yMax = Math.max(...formatted.map((d) => d.y));
    const absoluteMax = Math.max(Math.abs(yMin), Math.abs(yMax));

    // set min and max thresholds to 25% of absolute max
    const yMaxThreshold = absoluteMax * 0.25;
    const yMinThreshold = absoluteMax * -0.25;

    if (yMin > yMinThreshold) {
      yMin = yMinThreshold;
    }
    if (yMax < yMaxThreshold) {
      yMax = yMaxThreshold;
    }

    return [yMin.toString(), yMax.toString()];
  }, [formatted]);

  // Separate positive and negative data for dual color chart
  const separatedChangeTrendData = useMemo(() => {
    let lastPositiveIndex = -1;

    const positiveData = formatted.map((d, i) => {
      if (d.y >= 0) {
        lastPositiveIndex = i;
        return {...d};
      }
      if (i === lastPositiveIndex + 1) {
        // in this case we half the value to smooth the curve from + to -
        lastPositiveIndex = i;
        return {...d, y: d.y / 2 || 0};
      }
      return {...d, y: 0};
    });
    const negativeData = formatted.map((d) => ({
      ...d,
      y: d.y < 0 ? d.y : 0,
    }));

    if (!positiveData || !negativeData) return null;

    // Due to the chart configuration in this file, the labels Positive Change
    // and Negative Change and not surfaced to the user. The `renderTooltip` method
    // uses the `tooltipLabel` to display the label to the user.
    return {
      'Positive Change': positiveData,
      'Negative Change': negativeData,
    };
  }, [formatted]);

  const overviewChange = useMemo(
    () => formatted[formatted.length - 1]?.y?.toFixed(1),
    [formatted],
  );

  return {
    data: separatedChangeTrendData,
    isFetching,
    errorMonthlyPeriod,
    errorQuarterlyPeriod,
    errorMonthlyYear,
    errorQuarterlyYear,
    yDomain,
    overviewChange,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
