import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/feature-access/FeatureAccessModal.styles';
import {useTranslation} from '@/hooks/useTranslation';

function FeatureAccessDefaultModal({
  onClose = () => {},
  onSubmit = () => {},
  status = 'initial',
}) {
  const {getI18N} = useTranslation();
  const {
    enterpriseFeature,
    thanks,
    offerUpgrade,
    upgradeFeedback,
    interest,
    closeLabel,
  } = getI18N('featureAccess');

  return (
    <>
      <Box sx={styles.image} />
      <Box sx={styles.textContainer}>
        <Typography variant="body1" sx={styles.text}>
          {status === 'initial' ? enterpriseFeature : thanks}
        </Typography>
        <Typography variant="caption">
          {status === 'initial' ? offerUpgrade : upgradeFeedback}
        </Typography>
      </Box>
      <BaseButton onClick={status === 'initial' ? onSubmit : onClose}>
        {status === 'initial' ? interest : closeLabel}
      </BaseButton>
    </>
  );
}

FeatureAccessDefaultModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
};

export default FeatureAccessDefaultModal;
