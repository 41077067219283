import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';

import {setGranularity} from '@/store/modules/filters/actions';
import {setThreatAnalyticsArea} from '@/store/modules/user/actions';

import {styles} from '@/components/filters/Filters.styles';
import BaseChip from '@/components/common/buttons/BaseChip';
import {useTranslation} from '@/hooks/useTranslation';
import {useGranularity} from '@/hooks/charts/useGranularity';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {getLocation} from '@/selectors';

function FilterGranularity() {
  const dispatch = useDispatch();
  const {track} = useSegmentAnalytics();
  const {getI18N} = useTranslation();
  const {isRadiusEnabled, isDistrictEnabled, isCityEnabled, granularity} =
    useGranularity();
  const {name: locationName} = useSelector(getLocation);
  const {tabRadius, tabDistrict, tabCity} = getI18N('threatAnalytics');

  const handleGranularityTab = useCallback(
    (value) => {
      dispatch(setGranularity(value));
      track('Granularity Tab Changed', {
        granularity: value,
        location: locationName,
      });
      if (value === 'district') {
        dispatch(setThreatAnalyticsArea({shape: 'hood-polygon'}));
      } else if (value === 'city') {
        dispatch(setThreatAnalyticsArea({shape: 'city-polygon'}));
      } else {
        dispatch(setThreatAnalyticsArea({shape: 'circle'}));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (isRadiusEnabled) {
      handleGranularityTab('radius');
    } else if (isDistrictEnabled) {
      handleGranularityTab('district');
    } else if (isCityEnabled) {
      handleGranularityTab('city');
    }
  }, [handleGranularityTab, isCityEnabled, isDistrictEnabled, isRadiusEnabled]);

  return (
    <Box sx={styles.boxChip}>
      <Box sx={styles.wrapperChip}>
        <BaseChip
          key="granularity-radius"
          label={tabRadius}
          selected={granularity === 'radius'}
          onClick={() => handleGranularityTab('radius')}
          disabled={!isRadiusEnabled}
          connected
          position="first"
        />
      </Box>
      <Box sx={styles.wrapperChip}>
        <BaseChip
          key="granularity-district"
          label={tabDistrict}
          selected={granularity === 'district'}
          onClick={() => handleGranularityTab('district')}
          disabled={!isDistrictEnabled}
          connected
          position="middle"
        />
      </Box>
      <Box sx={styles.wrapperChip}>
        <BaseChip
          key="granularity-city"
          label={tabCity}
          selected={granularity === 'city'}
          onClick={() => handleGranularityTab('city')}
          disabled={!isCityEnabled}
          connected
          position="last"
        />
      </Box>
    </Box>
  );
}

export default FilterGranularity;
