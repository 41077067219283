import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@mui/material/Box';

import {base_languages as I18N} from '@languages';
import {Divider} from '@mui/material';
import {
  getFromDate,
  getIsYearlyData,
  getIsMonthlyData,
  getIsQuarterlyData,
  getMaxDate,
  getMinDate,
  getToDate,
} from '@/selectors';
import {changeFiltersDateRange} from '@/store/modules/filters/actions';
import DatePickerBO from '@/components/common/pickers/DatePickerBO';
import {useTranslation} from '@/hooks/useTranslation';

const useStyles = () => ({
  root: {
    width: '100%',
    textAlign: 'center',
    marginBlock: '4px 16px',
  },
  pickerRow: {
    display: 'flex',
    justifyContent: 'left',
  },
  pickerCol: {
    width: '40%',
    display: 'inline-block',
  },
  pickerLabel: (theme) => ({
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  }),
});

function FilterDate() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {locale} = useTranslation();

  const minDate = useSelector(getMinDate);
  const maxDate = useSelector(getMaxDate);
  const fromDate = useSelector(getFromDate);
  const toDate = useSelector(getToDate);
  const isQuarterlyData = useSelector(getIsQuarterlyData);
  const isYearlyData = useSelector(getIsYearlyData);
  const isMonthlyData = useSelector(getIsMonthlyData);

  const handleFromChanged = (fromDate) => {
    dispatch(changeFiltersDateRange(fromDate, toDate));
  };

  const handleToChanged = (toDate) => {
    dispatch(changeFiltersDateRange(fromDate, toDate));
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.pickerRow}>
        <Box sx={styles.pickerCol}>
          {(isYearlyData || isQuarterlyData) && (
            <DatePickerBO
              views={['year']}
              label={I18N[locale].from}
              onChange={handleFromChanged}
              value={fromDate}
              minDate={minDate}
              maxDate={toDate}
            />
          )}
          {isMonthlyData && (
            <DatePickerBO
              views={['month', 'year']}
              label={I18N[locale].from}
              onChange={handleFromChanged}
              value={fromDate}
              minDate={minDate}
              maxDate={toDate}
            />
          )}
          {!isMonthlyData && !(isYearlyData || isQuarterlyData) && (
            <DatePickerBO
              views={['day', 'month', 'year']}
              label={I18N[locale].from}
              onChange={handleFromChanged}
              value={fromDate}
              minDate={minDate}
              maxDate={toDate}
            />
          )}
        </Box>
        <Divider orientation="vertical" sx={{margin: 1}} />
        <Box sx={styles.pickerCol}>
          {(isYearlyData || isQuarterlyData) && (
            <DatePickerBO
              views={['year']}
              label={I18N[locale].to}
              onChange={handleToChanged}
              value={toDate}
              minDate={fromDate}
              maxDate={maxDate}
            />
          )}
          {isMonthlyData && (
            <DatePickerBO
              views={['month', 'year']}
              label={I18N[locale].to}
              onChange={handleToChanged}
              value={toDate}
              minDate={fromDate}
              maxDate={maxDate}
            />
          )}
          {!isMonthlyData && !(isYearlyData || isQuarterlyData) && (
            <DatePickerBO
              views={['day', 'month', 'year']}
              label={I18N[locale].to}
              onChange={handleToChanged}
              value={toDate}
              minDate={fromDate}
              maxDate={maxDate}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default FilterDate;
