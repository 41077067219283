import {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

import {useChart} from '@/hooks/api/useChart';
import {API_FORECAST, API_RADIUS_FORECAST} from '@/hooks/api/constants';

export const useForecastTrend = ({
  id,
  title,
  granularity,
  type = 'crime',
  forecastMonths = 3,
  disabled = false,
}) => {
  const {
    data: forecastTrendData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path: granularity === 'radius' ? API_RADIUS_FORECAST : API_FORECAST,
    disabled,
    customParams: {
      type,
      forecastMonths,
    },
  });

  const rawData = useMemo(() => {
    const data = forecastTrendData?.chartData;
    if (!data || isEmpty(data) || data.length === 0) {
      return [];
    }
    return data;
  }, [forecastTrendData]);

  const data = useMemo(() => {
    if (rawData.length === 0 || isFetching || error) return [];
    const lastIndex = rawData.findLastIndex(
      ({forecastedValue}) => !forecastedValue,
    );
    return rawData.map(
      (
        {date, estimatedValue, trendValue, forecastedValue, confidenceInterval},
        index,
      ) => ({
        date,
        estimatedValue,
        trendValue,
        forecastedValue: index === lastIndex ? trendValue : forecastedValue,
        ciUpper90: confidenceInterval?.upper90 ?? trendValue,
        ciLower90: confidenceInterval?.lower90 ?? trendValue,
        ciUpper75: confidenceInterval?.upper75 ?? trendValue,
        ciLower75: confidenceInterval?.lower75 ?? trendValue,
      }),
    );
  }, [rawData, isFetching, error]);

  return {
    rawData,
    data,
    isFetching,
    error,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
