export const styles = {
  root: (theme) => ({
    padding: 2,
    borderRadius: '6px',
    backgroundColor: theme.palette.background.dark,
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectionText: {
    alignSelf: 'center',
  },
  openButton: (theme) => ({
    color: theme.palette.secondary.light,
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  }),
  chipContainer: {
    paddingTop: 1,
    justifyContent: 'center',
    display: 'flex',
    gap: 1,
  },
};
