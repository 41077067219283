import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {X} from '@phosphor-icons/react';
import {Box, Typography, useTheme} from '@mui/material';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {BaseIconButton} from '@/components/common/buttons';
import {useTranslation} from '@/hooks/useTranslation';
import {setActiveCity} from '@/store/modules/filters/actions';

const useStyles = () => ({
  typographyStyle: {fontSize: '12px', paddingRight: '5px'},
  container: {display: 'flex', justifyContent: 'center', alignItems: 'center'},
});

function ActionFabFreeRoaming({sx}) {
  const theme = useTheme();
  const styles = useStyles();
  const {track} = useSegmentAnalytics();
  const dispatch = useDispatch();
  const {getI18N} = useTranslation();

  const {removeBoundaries} = getI18N('reCenterComp');

  const onFabCenter = () => {
    track('Enabled free roaming', {leftArea: 'City'});
    dispatch(setActiveCity(null));
  };

  return (
    <Box
      sx={(theme) => ({
        ...(typeof sx === 'function' ? sx(theme) : sx),
        backgroundColor: theme.palette.background.dark,
        borderRadius: '6px',
      })}>
      <BaseIconButton
        tooltipPlacement="bottom"
        onClick={onFabCenter}
        height={32}
        width={148}>
        <Box sx={styles.container}>
          <Typography sx={styles.typographyStyle}>
            {removeBoundaries}
          </Typography>
          <X size={16} color={theme.palette.text.primary} />
        </Box>
      </BaseIconButton>
    </Box>
  );
}

ActionFabFreeRoaming.propTypes = {
  sx: PropTypes.any,
};

export default ActionFabFreeRoaming;
