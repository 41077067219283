import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import {useTranslation} from '@/hooks/useTranslation';
import {useMoment} from '@/hooks/useMoment';

import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseLegend from '@/components/common/charts/BaseLegend';
import BaseArea from '@/components/common/charts/XYCharts/BaseArea';

function DataSourceTrend({
  data = {chartData: []},
  isLoading = false,
  showLegend = false,
  height,
  width,
}) {
  const {locale, getSourceTypesTranslation} = useTranslation();
  const {moment} = useMoment();
  const [disabled, setDisabled] = useState([]);

  const formattedData = useMemo(() => {
    const d = data?.chartData;
    if (!d || d.length === 0) {
      return {};
    }

    const result = {};
    d.filter((record) => record.isParent).forEach(({date, sourceId, count}) => {
      const source = getSourceTypesTranslation(sourceId);
      if (!result[source]) {
        result[source] = [];
      }
      result[source].push({
        x: moment(date).local().toDate(),
        y: count,
      });
    });
    return result;
  }, [data, locale]);

  const seriesLength = useMemo(
    () => Object.keys(formattedData).length || 1,
    [formattedData],
  );

  const legend = useMemo(
    () =>
      Object.keys(formattedData).map((key) => ({
        label: key,
        value: key,
      })) || [],
    [formattedData],
  );

  const filterData = useMemo(() => {
    if (disabled.length === 0) {
      return formattedData;
    }
    const result = {};
    Object.keys(formattedData).forEach((key) => {
      if (!disabled.includes(key)) {
        result[key] = formattedData[key];
      } else {
        result[key] = formattedData[key].map((d) => ({
          ...d,
          y: 0,
          disabled: true,
        }));
      }
    });
    return result;
  }, [disabled, formattedData]);

  const handleLegendClick = (value) => {
    if (disabled.includes(value)) {
      setDisabled(disabled.filter((v) => v !== value));
    } else {
      setDisabled([...disabled, value]);
    }
  };

  const yMax = useMemo(() => {
    const maxY = Object.values(formattedData).reduce((acc, curr) => {
      const max = Math.max(...curr.map((d) => d.y));
      return max > acc ? max : acc;
    }, 0);
    return maxY;
  }, [formattedData]);

  return (
    <Box>
      <BaseXYChart
        isLoading={isLoading}
        height={height}
        width={width}
        seriesLength={seriesLength}
        dateFormat="MM/YYYY"
        yScale={{
          type: 'linear',
          domain: [0, Math.max(5, yMax)],
        }}
        xScale={{
          type: 'time',
        }}>
        <BaseArea type="stacked" data={filterData} />
      </BaseXYChart>
      {showLegend && (
        <BaseLegend
          sx={{marginTop: 2}}
          isLoading={isLoading}
          labels={legend}
          disabled={disabled}
          onClick={handleLegendClick}
        />
      )}
    </Box>
  );
}

DataSourceTrend.propTypes = {
  showLegend: PropTypes.bool,
  height: PropTypes.number,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

export default DataSourceTrend;
