import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import {Button} from '@mui/material';

const styles = {
  root: () => ({
    textTransform: 'none',
    minWidth: 0,
  }),
};

function BaseButton({
  onClick,
  href,
  disabled = false,
  loading,
  color = 'primary',
  variant = 'contained',
  children,
  sx = {},
  ...props
}) {
  if (!onClick && !href) {
    onClick = () => {};
  }
  const Component = loading ? LoadingButton : Button;

  return (
    <Component
      size="small"
      disableElevation
      onClick={onClick}
      href={href}
      disabled={disabled}
      loading={loading}
      color={color}
      variant={variant}
      {...props}
      sx={(theme) => ({
        ...styles.root(theme),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}>
      {children}
    </Component>
  );
}

BaseButton.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.oneOf(['inherit', 'primary', 'secondary', 'error']),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  children: PropTypes.node.isRequired,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default BaseButton;
