export const styles = {
  root: {
    border: '1px solid transparent',
    height: '24px',
    fontSize: '0.875rem',
  },
  firstChip: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  middleChip: {
    borderRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  lastChip: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginRight: 0,
    width: '75px',
  },
  outlined: (theme) => ({
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiChip-label': {
      color: theme.palette.primary.main,
    },
  }),
  chipProgress: {
    position: 'absolute',
    right: '50%',
  },
};
