import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@mui/material/Box';

import {base_languages as I18N} from '@languages';
import {
  toggleAllQuarters,
  toggleQuarter,
  toggleAllMonths,
} from '@/store/modules/filters/actions';
import {getQuarters} from '@/selectors';
import BaseChip from '@/components/common/buttons/BaseChip';
import {useTranslation} from '@/hooks/useTranslation';
import FilterItem from '@/components/filters/FilterItem';
import BaseCheckbox from '@/components/common/checkboxes/BaseCheckbox';

const useStyles = () => ({
  root: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'left',
    gap: 1,
    marginBlock: 1,
  },
});

function FilterQuarters() {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {locale, getI18N} = useTranslation();
  const quarters = useSelector(getQuarters);

  const {crimeFilterQuarters} = getI18N('tooltips');
  const allSelected = Object.values(quarters).every(Boolean);
  const someSelected = Object.values(quarters).some(Boolean);

  const handleToggleQuarter = (quarter) => {
    dispatch(toggleQuarter(quarter));
  };

  const handleToggleAllQuarters = () => {
    dispatch(toggleAllQuarters());
    dispatch(toggleAllMonths());
  };

  return (
    <FilterItem
      label={
        <BaseCheckbox
          bold
          tooltip={crimeFilterQuarters}
          label={I18N[locale].timeOfYear}
          checked={allSelected}
          indeterminate={someSelected && !allSelected}
          onClick={handleToggleAllQuarters}
        />
      }>
      <Box sx={styles.root}>
        {Object.keys(quarters).map((key) => (
          <BaseChip
            key={key}
            label={I18N[locale].filters.quarters[key]}
            selected={quarters[key]}
            onClick={() => handleToggleQuarter(key)}
          />
        ))}
      </Box>
    </FilterItem>
  );
}

export default FilterQuarters;
