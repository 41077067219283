import {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Box, Typography, useTheme} from '@mui/material';
import {ArrowDown, ArrowUp} from '@phosphor-icons/react';

import {useTranslation} from '@/hooks/useTranslation';
import BaseInjectedTypography from '@/components/common/typography/BaseInjectedTypography';
import {styles} from '@/components/analytics/charts/ChangeOverview.styles';
import BaseSkeleton from '@/components/common/BaseSkeleton';

/**
 * Displays important information about the selected radius, district or city
 *
 * @param {string} granularity - The current selected tab
 * @returns {React.Component} - The component
 *
 * @example
 * <KeyTakeAway granularity="radius" urlPath={API_KEY_TAKEAWAY_RADIUS || API_KEY_TAKEAWAY} />
 */
function ChangeHeader({
  overallChange,
  startDate,
  endDate,
  period,
  aggregation,
  isLoading,
}) {
  const {getI18N} = useTranslation();
  const theme = useTheme();

  const {
    yearOverYearChange,
    periodOverPeriodChange,
    yearOverYearNoChange,
    periodOverPeriodNoChange,
    increase: increaseLabel,
    decrease: decreaseLabel,
    month: monthLabel,
    quarter: quarterLabel,
  } = getI18N('changeOverview');

  const getColor = (value) => {
    if (value > 0) return theme.palette.quintile[5];
    return theme.palette.quintile[2];
  };

  const changeText = useMemo(() => {
    if (period === 'periodOverPeriod') {
      if (overallChange === 0) {
        return periodOverPeriodNoChange;
      }
      return periodOverPeriodChange;
    }
    if (period === 'yearOverYear') {
      if (overallChange === 0) {
        return yearOverYearNoChange;
      }
      return yearOverYearChange;
    }
  }, [period, overallChange]);

  const currentPeriod = useMemo(() => {
    if (aggregation === 'monthly') {
      return startDate;
    }
    return `${startDate} - ${endDate}`;
  }, [aggregation, startDate, endDate]);

  if (isLoading) return <BaseSkeleton width="100%" height="64px" />;

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.summary}>{overallChange}%</Typography>
      {overallChange > 0 ? (
        <ArrowUp size={40} weight="bold" color={getColor(overallChange)} />
      ) : (
        <ArrowDown size={40} weight="bold" color={getColor(overallChange)} />
      )}
      <BaseInjectedTypography
        textVariant="caption"
        replacementVariant="caption"
        language={changeText}
        replacements={{
          increase: overallChange > 0 ? increaseLabel : decreaseLabel,
          currentPeriod,
          period: aggregation === 'monthly' ? monthLabel : quarterLabel,
        }}
        replacementStyles={{
          currentPeriod: {
            fontWeight: 'bold',
          },
        }}
        styles={styles.textInjection}
      />
    </Box>
  );
}

ChangeHeader.propTypes = {
  overallChange: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  aggregation: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ChangeHeader;
