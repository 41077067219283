import {useMemo} from 'react';

import {
  API_CHANGE_TRENDLINE,
  API_CHANGE_RADIUS_TRENDLINE,
} from '@/hooks/api/constants';
import {useMoment} from '@/hooks/useMoment';
import {useChart} from '@/hooks/api/useChart';

export const useTrendLine = ({
  id = 'trendline',
  title = 'Trendline',
  granularity,
  aggregation,
  type = 'crime',
}) => {
  const {moment} = useMoment();
  const {
    data: trendLineData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path:
      granularity !== 'radius'
        ? API_CHANGE_TRENDLINE
        : API_CHANGE_RADIUS_TRENDLINE,
    exactDates: false,
    customParams: {
      timeAggregation: 'monthly',
      type,
    },
  });

  const formatted = useMemo(() => {
    const data = trendLineData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }

    return data.map((data) => ({
      x: moment(data.date).local().toDate(),
      y:
        aggregation === 'monthly'
          ? data.trendValue
          : (data.trendValue / 30).toFixed(2),
    }));
  }, [trendLineData, aggregation]);

  const rawData = useMemo(() => {
    const data = trendLineData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }
    return data;
  }, [trendLineData]);

  return {
    rawData,
    data: formatted,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
