import React from 'react';
import {useSelector} from 'react-redux';

import {Box, Divider, Typography} from '@mui/material';

import {base_languages as I18N} from '@languages';
import {getLayers, getIsQuarterlyData, getIsDateTimeData} from '@/selectors';
import {useTranslation} from '@/hooks/useTranslation';
import {styles} from '@/components/filters/Filters.styles';
import FilterItem from '@/components/filters/FilterItem';
import FilterDate from '@/components/filters/FilterDate';
import FilterQuarters from '@/components/filters/FilterQuarters';
import FilterCategories from '@/components/filters/FilterCategories';
import FilterRange from '@/components/filters/FilterRange';
import FilterTimeOfDay from '@/components/filters/FilterTimeOfDay';
import FilterSources from '@/components/filters/FilterSources';
import FilterGranularity from '@/components/filters/FilterGranularity';
import FilterGlobalThreatsModel from '@/components/filters/FilterGlobalThreatsModel';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';

function Filters() {
  const {locale, getI18N} = useTranslation();

  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const isQuarterlyData = useSelector(getIsQuarterlyData);
  const isDateTimeData = useSelector(getIsDateTimeData);

  return (
    <Box sx={styles.root}>
      <Typography variant="body2" sx={styles.header}>
        {getI18N('eventFilter')}
      </Typography>
      <Divider />
      <FilterItem>
        <FilterGranularity />
      </FilterItem>
      <FilterItem label={I18N[locale].dates}>
        <FilterDate />
      </FilterItem>
      {isQuarterlyData && <FilterQuarters />}
      {isDistrictsLayer && (
        <FilterItem label={I18N[locale].crimeQuantile}>
          <FilterRange />
        </FilterItem>
      )}
      <FilterSources />
      <FilterCategories />
      {isDateTimeData && <FilterTimeOfDay />}
      <FeatureAccessWrapper feature="gtm">
        <FilterGlobalThreatsModel />
      </FeatureAccessWrapper>
    </Box>
  );
}

export default Filters;
