import PropTypes from 'prop-types';
import BaseModal from '@/components/common/modals/BaseModal';

import FeatureAccessDefaultModal from '@/components/feature-access/FeatureAccessDefaultModal';

function FeatureAccessModal({
  isOpen = false,
  onOpen = () => {},
  onClose = () => {},
  onSubmit = () => {},
  status = 'initial',
  modal = {
    content: FeatureAccessDefaultModal,
    sx: {root: {}, content: {}, backdrop: {}},
    header: {hide: false},
  },
  isContained,
}) {
  return (
    <BaseModal
      sx={{
        root: modal.sx.root,
        backdrop: modal.sx.backdrop,
        content: modal.sx.content,
      }}
      hideHeader={modal.header.hide}
      disablePortal={isContained}
      disableAutoFocus={isContained}
      disableEnforceFocus={isContained}
      disableScrollLock={isContained}
      open={isOpen}
      onClose={onClose}>
      {modal.content({onClose, onSubmit, status, onOpen})}
    </BaseModal>
  );
}

FeatureAccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  modal: PropTypes.object,
  onOpen: PropTypes.func,
  isContained: PropTypes.bool,
};

export default FeatureAccessModal;
