import {useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BaseButton from '@/components/common/buttons/BaseButton';
import {styles} from '@/components/feature-access/FeatureAccessModal.styles';
import {useTranslation} from '@/hooks/useTranslation';

function FeatureAccessForecastModal({
  onOpen = () => {},
  onSubmit = () => {},
  status = 'initial',
}) {
  const {getI18N} = useTranslation();
  const {forecastTeaser, thanks, contact, forecastFeedback} =
    getI18N('featureAccess');

  useEffect(() => {
    if (status === 'initial') {
      onOpen();
    }
  }, [status]);

  return (
    <Box sx={styles.forecastModal}>
      <Box sx={styles.textContainer}>
        <Typography variant="caption">
          {status === 'initial' ? forecastTeaser : forecastFeedback}
        </Typography>
      </Box>
      <BaseButton onClick={status === 'initial' ? onSubmit : () => {}}>
        {status === 'initial' ? contact : thanks}
      </BaseButton>
    </Box>
  );
}

FeatureAccessForecastModal.propTypes = {
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  onOpen: PropTypes.func,
};

export default FeatureAccessForecastModal;
