export const styles = {
  root: (theme) => ({
    flex: '1 1 auto',
    overflow: 'auto',
    marginBottom: 2,
    marginRight: 1,
    marginLeft: 1,
    borderRadius: 2,
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('sm')]: {
      marginRight: 2,
    },
  }),
  filterItem: {
    marginTop: 0,
    marginInline: 0,
  },
  visibilityFilters: {
    display: 'flex',
    gap: 1,
    marginBlock: 1,
  },
};
