import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import BaseIconButton from '@common/buttons/BaseIconButton';
import {X} from '@phosphor-icons/react';
import {styles} from '@/components/common/modals/BaseModal.styles';

function BaseModal({
  children,
  title = '',
  sx = {root: {}, content: {}, backdrop: {}},
  open = false,
  onClose = () => {},
  disablePortal,
  disableAutoFocus,
  disableEnforceFocus,
  disableScrollLock,
  hideHeader,
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={sx.root}
      disablePortal={disablePortal}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
      disableScrollLock={disableScrollLock}
      slotProps={{
        backdrop: {
          sx: sx.backdrop,
        },
      }}>
      <Box
        sx={(theme) => ({
          ...styles.content(theme),
          ...(typeof sx.content === 'function'
            ? sx.content(theme)
            : sx.content),
        })}>
        {!hideHeader && (
          <Box sx={styles.header}>
            <Typography sx={styles.title}>{title}</Typography>
            <BaseIconButton icon={X} onClick={onClose} sx={styles.icon} />
          </Box>
        )}
        <Box sx={styles.body}>{children}</Box>
      </Box>
    </Modal>
  );
}

BaseModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.any,
  sx: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disablePortal: PropTypes.bool,
  hideHeader: PropTypes.bool,
  disableEnforceFocus: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableScrollLock: PropTypes.bool,
};

export default BaseModal;
