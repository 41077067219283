import {useMemo} from 'react';

import {useChart} from '@/hooks/api/useChart';
import {API_TIME_SERIES, API_TIME_SERIES_RADIUS} from '@/hooks/api/constants';
import {useMoment} from '@/hooks/useMoment';

export const useTimeSeries = ({
  id = 'time-series',
  title = 'Time Series',
  granularity,
  aggregation,
  type,
}) => {
  const {moment} = useMoment();
  const path = useMemo(
    () => (granularity === 'radius' ? API_TIME_SERIES_RADIUS : API_TIME_SERIES),
    [granularity],
  );

  const {
    data: timeSeriesData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  } = useChart({
    id,
    title,
    granularity,
    path,
    exactDates: false,
    customParams: {
      timeAggregation: aggregation,
      type,
    },
  });

  const formattedAverageData = useMemo(() => {
    const data = timeSeriesData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }
    return data.map((record) => ({
      x: moment(record.date).local().toDate(),
      y: record.average,
    }));
  }, [timeSeriesData, aggregation]);

  const rawData = useMemo(() => {
    const data = timeSeriesData?.chartData;
    if (!data || data.length === 0) {
      return [];
    }
    return data;
  }, [timeSeriesData]);

  return {
    rawData,
    averageData: formattedAverageData,
    error,
    isFetching,
    downloadCSV,
    copyCSV,
    downloadImage,
    copyImage,
  };
};
