import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useApi} from '@hooks/api/useApi';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {
  getSelectedCategories,
  getSelectedMonths,
  getSelectedTimeslices,
  getSelectedSources,
  getThreatAnalyticsAreaLat,
  getThreatAnalyticsAreaLon,
  getThreatAnalyticsAreaRadius,
  getClickedHoodId,
  getLocation,
  getClickedLocationId,
  getSelectedDistrictIds,
  getAnalyticsRequestEnabled,
  getUserProfile,
} from '@/selectors';

import {useMoment} from '@/hooks/useMoment';
import {API_REPORTS_AVAILABLE} from '@/hooks/api/constants';
import {useRouter} from '@/hooks/useRouter';
import {convertDistance} from '@/utils/utils';

export const useReport = ({
  name,
  format,
  reportType,
  customOptions,
  onSuccess,
}) => {
  const {getISOfromDate, getISOtoDate} = useMoment();
  const {useGetQuery, postReport} = useApi();
  const {isReportRoute} = useRouter();
  const {track} = useSegmentAnalytics();

  const sources = useSelector(getSelectedSources);
  const categories = useSelector(getSelectedCategories);
  const timeSlices = useSelector(getSelectedTimeslices);
  const months = useSelector(getSelectedMonths);
  const {id: locationId} = useSelector(getLocation);
  const latitude = useSelector(getThreatAnalyticsAreaLat);
  const longitude = useSelector(getThreatAnalyticsAreaLon);
  const radius = useSelector(getThreatAnalyticsAreaRadius);
  const {units} = useSelector(getUserProfile);
  const hoodId = useSelector(getClickedHoodId);
  const savedLocationId = useSelector(getClickedLocationId);
  const selectedDistrictsIds = useSelector(getSelectedDistrictIds);
  const isRequestEnabled = useSelector(getAnalyticsRequestEnabled);

  const [isLoading, setIsLoading] = useState(false);

  const determineUnits = () => {
    if (units === 'mi') return 'miles';
    if (units === 'km') return 'kilometers';
    if (units === 'm') return 'meters';
    return units;
  };

  const availableReports = useGetQuery({
    path: API_REPORTS_AVAILABLE,
    params: {
      locationId,
      hoodId: selectedDistrictsIds[0] ?? hoodId,
      latitude,
      longitude,
      radius,
      units: 'meters',
      fromDate: getISOfromDate(),
      toDate: getISOtoDate(),
      sources: JSON.stringify(sources),
      categories: JSON.stringify(categories),
      daytimes: JSON.stringify(timeSlices),
      months: JSON.stringify(months),
    },
    config: {
      enabled:
        !isReportRoute &&
        isRequestEnabled &&
        Boolean((latitude && longitude) || locationId),
    },
  });

  const requestReport = () => {
    const {granularity} = customOptions[0];
    const isCustomReport = customOptions.length > 1;

    const locationIdentifiers = {
      locationId,
      ...((granularity !== 'city' || isCustomReport) && {
        hoodId: selectedDistrictsIds[0] ?? hoodId,
      }),
      ...((granularity === 'radius' || isCustomReport) && {
        latitude,
        longitude,
        radius: convertDistance(radius, units),
        units: determineUnits(),
      }),
    };

    postReport.mutate(
      {
        body: {
          name,
          format,
          reportType,
          locationId,
          ...((granularity === 'radius' || isCustomReport) && {
            savedLocationId,
          }),
          customOptions,
          filters: {
            fromDate: getISOfromDate(),
            toDate: getISOtoDate(),
            sources,
            categories,
            daytimes: timeSlices,
            months,
            ...locationIdentifiers,
          },
        },
      },
      {
        onSuccess,
      },
    );

    track('Report Requested', {
      reportType,
      granularity,
      ...((granularity === 'radius' || isCustomReport) && {
        savedLocationId,
      }),
      ...locationIdentifiers,
    });
  };

  useEffect(() => {
    setIsLoading(postReport.isLoading);
  }, [postReport.isLoading]);

  return {isLoading, requestReport, availableReports};
};
