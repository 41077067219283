import {Box, Container, useTheme} from '@mui/material';

// Packages
import {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import debounce from 'lodash/debounce';

// MUI
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {ArrowsDownUp, MagnifyingGlass, X} from '@phosphor-icons/react';

// Constants
import {API_LOCATION, API_SAVED_LOCATIONS} from '@hooks/api/constants';
import {
  SAVED_SORT_OPTIONS,
  CHANGE_SORT_METRICS,
} from '@saved-locations/constants';
import {styles} from '@/components/views/SavedLocationsPage.styles';

// State Handlers
import {
  getClickedLocationId,
  getLocationAddress,
  getSavedLocations,
} from '@/selectors';
import {setSelectedLocation} from '@/store/modules/user/actions';
import {setRoutedToMap} from '@/store/modules/map/actions';
import {
  setSavedLocations,
  setSavedLocationControls,
} from '@/store/modules/saved-locations/actions';

// Hooks
import {useRouter} from '@/hooks/useRouter';
import {useSavedLocationsReportDownload} from '@/hooks/useSavedLocationsReportDownload';
import {useApi} from '@/hooks/api/useApi';
import {useTranslation} from '@/hooks/useTranslation';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useFeatureAccess} from '@/hooks/useFeatureAccess';

// Components
import SavedLocationListTile from '@/components/saved-locations/cards/SavedLocationListTile';
import SavedLocationModal from '@/components/saved-locations/SavedLocationModal';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import BaseTextField from '@/components/common/inputs/BaseTextField';
import BasePagination from '@/components/common/pagination/BasePagination';
import {BaseIconButton} from '@/components/common/buttons';
import BaseButton from '@/components/common/buttons/BaseButton';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import BaseSkeleton from '@/components/common/BaseSkeleton';

function SavedLocationsPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {pushMapRoute, isSavedRoute} = useRouter();
  const {getFeatureAccess} = useFeatureAccess();
  const {useGetQuery, getSavedLocations: getLocations} = useApi();
  const {getI18N, getSortTranslations} = useTranslation();
  const {track} = useSegmentAnalytics();
  const {downloadCSVReport, isLoading: reportIsDownloading} =
    useSavedLocationsReportDownload();

  const {
    locations: savedLocations,
    filters,
    controls,
    tags: userTags,
  } = useSelector(getSavedLocations);
  const savedLocationId = useSelector(getClickedLocationId);
  const searchAddress = useSelector(getLocationAddress);
  const hideChange = getFeatureAccess('saved-locations-change', 'hide');

  const {
    sortOptions,
    sortButtonText,
    searchInputText,
    sortOrderTooltip,
    noSavedLocationsFirst,
    clickHere,
    noSavedLocationsSecond,
  } = getI18N('savedLocations');
  const download = getI18N('download');
  const {changeMetric: changeMetricLabel} = getI18N('chartSelection');

  const [locationIds, setLocationIds] = useState([]);
  const [locationDetails, setLocationDetails] = useState({});
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('change');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [selectedSavedLocation, setSelectedSavedLocation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [changeMetric, setChangeMetric] = useState(
    CHANGE_SORT_METRICS[1].value,
  );

  const {data: locations} = useGetQuery({
    path: API_LOCATION,
    params: {
      locationIds: JSON.stringify(locationIds),
    },
    config: {
      enabled: locationIds.length > 0,
    },
  });

  const params = useMemo(
    () => ({
      ...filters,
      page,
      take: 12,
      sortBy: `${sort}.${sortDirection}`,
      search: controls.search,
      changeMetric: controls.changeMetric,
    }),
    [filters, controls, page, sort, sortDirection],
  );

  const {
    data: savedLocationSearch,
    isFetching: isLoading,
    refetch,
  } = useGetQuery({
    path: API_SAVED_LOCATIONS,
    params,
    config: {
      enabled: isSavedRoute,
    },
  });

  // Navigate to location on map page
  const navigateToSavedLocation = (id) => {
    if (!id) return;
    const savedLocation = savedLocations.find((location) => location.id === id);
    if (!savedLocation) {
      return;
    }
    if (savedLocationId !== savedLocation.id) {
      dispatch(setSelectedLocation(savedLocation));
    }
    pushMapRoute();
    dispatch(setRoutedToMap(true));
  };

  // Page changed event handler
  const handlePagination = (_, page) => {
    setPage(page + 1);
    track('Changed Threat Saved Locations Page', {
      page,
    });
  };

  // Search changed event handler
  const handleSearchChange = (event) => {
    const value = event.target.value || '';
    if (value.length > 0) {
      handlePagination(null, 0);
    }
    setSearch(value);
    track('Search on Threat Saved Locations Page', {
      search: value,
    });
  };

  const handleDownloadSavedLocations = () => {
    downloadCSVReport();
    track('User downloaded their locations');
  };

  useEffect(() => {
    if (savedLocationSearch && !isLoading) {
      dispatch(setSavedLocations(savedLocationSearch.data));
      setPagination(savedLocationSearch.meta);
    }
  }, [dispatch, savedLocationSearch, isLoading]);

  // If search from modal
  useEffect(() => {
    if (searchAddress && search !== searchAddress) {
      setSearch(searchAddress);
    }
  }, [searchAddress]);

  useEffect(() => {
    const debouncedSearch = debounce(() => {
      dispatch(
        setSavedLocationControls({
          search: search.length > 0 ? search : undefined,
        }),
      );
    }, 250);

    debouncedSearch();

    return () => {
      debouncedSearch.cancel();
    };
  }, [dispatch, search]);

  useEffect(() => {
    let details = {};

    // Create object mapping each user location to a location summary
    if (savedLocations && locations) {
      details = savedLocations.reduce(
        (
          acc,
          {
            id,
            locationId,
            coverageTypes,
            coverageStart,
            coverageEnd,
            radiusMeters,
            latitude,
            longitude,
          },
        ) => {
          const l = locations.find(({id}) => id === locationId) || {};
          return {
            ...acc,
            [id]: {
              defaultAddress: l.country ? `${l.name}, ${l.country}` : l.name,
              coverage: coverageTypes,
              startDate: coverageStart,
              endDate: coverageEnd,
              radius: radiusMeters,
              latitude,
              longitude,
            },
          };
        },
        {},
      );
    }

    setLocationDetails(details);
  }, [locations, savedLocations]);

  // Get unique location ids from user locations
  useEffect(() => {
    const ids = savedLocations
      .map((location) => location.locationId)
      .filter((id) => id !== null);
    setLocationIds(Array.from(new Set(ids)));
  }, [savedLocations]);

  const getAllTags = useMemo(
    () => userTags?.map(({name}) => name) || [],
    [userTags],
  );

  const savedLocationList = useMemo(
    () =>
      SAVED_SORT_OPTIONS.map(({value, feature, action, tooltip, items}) => ({
        value,
        label: sortOptions[value],
        feature,
        action,
        tooltip: tooltip && sortOptions[tooltip],
        items: items?.map(({value}) => ({
          value,
          label: `${sortOptions[value]}`,
          onClick: () => setSort(value),
        })),
      })),
    [getFeatureAccess, sortOptions],
  );

  useEffect(() => {
    if (hideChange) {
      setSort('name');
    } else {
      setSort('change');
    }
    setSortDirection('DESC');
  }, [hideChange]);

  const changeOptions = useMemo(
    () =>
      CHANGE_SORT_METRICS.map(
        ({value, feature, action, tooltip, items, label}) => ({
          value,
          label: getSortTranslations(label),
          feature,
          action,
          disabled: getFeatureAccess(feature, 'disable'),
          tooltip: tooltip && sortOptions[tooltip],
          items: items?.map(({value}) => ({
            value,
            label: `${sortOptions[value]}`,
            onClick: () => setChangeMetric(value),
          })),
        }),
      ),
    [getFeatureAccess, sortOptions],
  );

  useEffect(() => {
    dispatch(setSavedLocationControls({changeMetric}));
  }, [dispatch, changeMetric]);

  return (
    <Box sx={styles.root}>
      <Container maxWidth="xl">
        <SavedLocationModal
          open={openModal && selectedSavedLocation !== null}
          savedLocation={selectedSavedLocation}
          tags={getAllTags}
          onSuccess={() => refetch()}
          handleClose={() => setOpenModal(false)}
        />
        <Box overflow="auto" sx={styles.container}>
          <Box sx={styles.header}>
            <BaseSelect
              sx={styles.sortSelect}
              value={changeMetric}
              variant="filled"
              onChange={(selected) => {
                setChangeMetric(selected?.target?.value);
                track('Selected Change Metric ', {sort: changeMetric});
              }}
              displayEmpty
              size="small"
              inputProps={{'aria-label': 'Without label'}}
              options={changeOptions}
              renderValue={(selected) => {
                const {label} = CHANGE_SORT_METRICS.find(
                  ({value}) => value === selected,
                );
                return `${changeMetricLabel}: ${getSortTranslations(label)}`;
              }}
            />
            <BaseSelect
              sx={styles.sortSelect}
              value={sort}
              variant="filled"
              onChange={(selected) => {
                setSort(selected?.target?.value);
                track('Sorted Saved Locations', {sort});
                if (sort === 'change') {
                  track('Sorted Saved Locations By Change', {
                    sort: controls.changeMetric,
                  });
                }
              }}
              displayEmpty
              size="small"
              inputProps={{'aria-label': 'Without label'}}
              options={savedLocationList}
              renderValue={(selected) =>
                `${sortButtonText}: ${sortOptions[selected]}`
              }
            />
            <BaseIconButton
              tooltip={sortOrderTooltip}
              sx={styles.header.actions}
              icon={ArrowsDownUp}
              onClick={() => {
                setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
              }}
            />
            <Box sx={styles.separator} />
            <FeatureAccessWrapper feature="saved-locations-downloads">
              <BaseButton
                variant="text"
                color="primary"
                sx={styles.downloadButton}
                disableRipple
                disabled={reportIsDownloading}
                onClick={handleDownloadSavedLocations}>
                {download}
              </BaseButton>
            </FeatureAccessWrapper>
            <BaseTextField
              placeholder={searchInputText}
              size="small"
              variant="filled"
              style={{
                maxWidth: '400px',
              }}
              value={search}
              onBlur={() => track('Searched Saved Locations', {search})}
              onChange={handleSearchChange}
              InputProps={{
                style: {
                  height: '32px',
                  backgroundColor: theme.palette.background.contrast.dark,
                  borderBottomLeftRadius: '6px',
                  borderBottomRightRadius: '6px',
                },
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    {getLocations.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <MagnifyingGlass size={20} />
                    )}
                  </InputAdornment>
                ),
                endAdornment:
                  search.length > 0 ? (
                    <InputAdornment position="end">
                      <BaseIconButton
                        tooltip="Clear Search"
                        size={28}
                        sx={{
                          backgroundColor: 'none',
                        }}
                        icon={X}
                        onClick={() => setSearch('')}
                      />
                    </InputAdornment>
                  ) : null,
              }}
            />
          </Box>
          <Box sx={styles.content}>
            <Box>
              {isLoading && (
                <Grid container spacing={0.5}>
                  {Array(6)
                    .fill(0)
                    .map((_, index) => (
                      <Grid item xs={12} key={index}>
                        <BaseSkeleton height="48px" />
                      </Grid>
                    ))}
                </Grid>
              )}
              {!isLoading && (
                <Grid container spacing={0.5}>
                  <>
                    <Grid item xs={12}>
                      <SavedLocationListTile header />
                    </Grid>
                    {savedLocations.map((savedLocation) => (
                      <Grid item xs={12} key={savedLocation.id}>
                        <SavedLocationListTile
                          sort={sort}
                          savedLocation={savedLocation}
                          details={locationDetails[savedLocation.id]}
                          searchText={search}
                          onEditClick={() => {
                            setSelectedSavedLocation(savedLocation);
                            setOpenModal(true);
                            track('Opened Saved Location Detail Drawer', {
                              savedLocationId: savedLocation.id,
                              locationName: savedLocation.name,
                            });
                          }}
                          onMapClick={() => {
                            track('Navigated to Saved Location', {
                              savedLocationId: savedLocation.id,
                              locationName: savedLocation.name,
                            });
                            navigateToSavedLocation(savedLocation.id);
                          }}
                        />
                      </Grid>
                    ))}
                  </>
                </Grid>
              )}
            </Box>
            {savedLocations.length > 0 && (
              <Box
                height="10vh"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center">
                <BasePagination
                  count={pagination.itemCount || 0}
                  page={pagination.page - 1 || 0}
                  onPageChange={handlePagination}
                  rowsPerPage={pagination.take || -1}
                />
              </Box>
            )}
            {savedLocations.length === 0 && !isLoading && (
              <Box
                height="10vh"
                width="100%"
                paddingTop={4}
                display="flex"
                alignItems="center"
                justifyContent="center">
                <Typography variant="subtitle1" color="textSecondary">
                  {noSavedLocationsFirst} <Link href="/map">{clickHere}</Link>{' '}
                  {noSavedLocationsSecond}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default SavedLocationsPage;
