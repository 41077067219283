import PropTypes from 'prop-types';
import millify from 'millify';

import {Box, Typography, useTheme} from '@mui/material';

import {Threshold} from '@visx/threshold';
import {curveBasis} from '@visx/curve';
import {scaleTime, scaleLinear} from '@visx/scale';
import {LinePath, Bar} from '@visx/shape';
import {AxisLeft, AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import {ParentSize} from '@visx/responsive';
import {Tooltip, useTooltip, defaultStyles} from '@visx/tooltip';
import {localPoint} from '@visx/event';

import BaseSkeleton from '@/components/common/BaseSkeleton';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {useMoment} from '@/hooks/useMoment';

function BaseThresholdChart({
  data,
  isLoading,
  width,
  height = 200,
  marginBottom = 20,
  marginLeft = 35,
  marginRight = 20,
  marginTop = 20,
  tooltip = [],
}) {
  const theme = useTheme();
  const {getChartColors} = useChartTheme();
  const {getMonthAbbreviation, moment} = useMoment();
  const {showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop} =
    useTooltip();

  const formatDate = (date) => moment(date).valueOf();

  const formatYAxis = (value, max, min) => {
    if (typeof value === 'number') {
      if (value >= 1000) return millify(value, {precision: 2});
      if (max - min <= 0.5) return value.toFixed(2);
      if (max - min < 5) return value.toFixed(1);
      return value.toFixed(0);
    }
  };

  const formatXAxis = (value) => {
    if (value instanceof Date) {
      return getMonthAbbreviation(value);
    }
  };

  return (
    <ParentSize style={{height: 'inherit'}}>
      {(parent) => {
        const w = width ?? parent.width;
        const h = height ?? parent.height;

        const xScale = scaleTime({
          domain: [
            Math.min(...data.map(({date}) => formatDate(date))),
            Math.max(...data.map(({date}) => formatDate(date))),
          ],
          range: [0, w],
        });

        let yMinDomain = Math.min(
          ...data.map((item) =>
            Math.min(
              ...Object.values(item).filter(
                (value) => typeof value === 'number',
              ),
            ),
          ),
        );

        let yMaxDomain = Math.max(
          ...data.map((item) =>
            Math.max(
              ...Object.values(item).filter(
                (value) => typeof value === 'number',
              ),
            ),
          ),
        );

        // Assure the chart has a minimum y-axis range of 15
        if (yMaxDomain - yMinDomain < 13) {
          const diff = 15 - (yMaxDomain - yMinDomain);
          yMinDomain -= diff;
          if (yMinDomain < 0) {
            yMaxDomain -= yMinDomain;
            yMinDomain = 0;
          }
        } else {
          yMinDomain -= (yMaxDomain - yMinDomain) * 0.15;
        }

        const yScale = scaleLinear({
          domain: [Math.max(yMinDomain, 0), yMaxDomain],
          range: [h, 0],
          nice: true,
        });

        const xMax = w - marginLeft - marginRight;
        const yMax = h - marginTop - marginBottom;
        const totalBars = data.length;
        const barPadding = 5;
        const barWidth = (xMax - (totalBars - 1) * barPadding) / totalBars;

        xScale.range([0, xMax]);
        yScale.range([yMax, 0]);

        const xPosition = ({date}) => xScale(formatDate(date));
        const handleMouseMove = (event) => {
          const point = localPoint(event);
          showTooltip({
            tooltipData: data.find(
              ({date}) =>
                moment(date).toISOString() ===
                moment(xScale.invert(point.x - 2 * barWidth))
                  .startOf('month')
                  .toISOString(),
            ),
            tooltipTop: event.clientY,
            tooltipLeft: point.x + (point.x > 240 ? -150 : 20),
          });
        };

        if (!w || !h) return null;

        if (isLoading)
          return (
            <BaseSkeleton
              width={`${w.toString()}px`}
              height={`${h.toString()}px`}
            />
          );

        return (
          <>
            <svg width={w} height={h} onMouseLeave={hideTooltip}>
              <rect
                width={w}
                height={h}
                fill="transparent"
                onMouseMove={handleMouseMove}
              />
              <Group
                left={marginLeft}
                top={marginTop}
                onMouseMove={handleMouseMove}>
                <AxisLeft
                  left={0}
                  scale={yScale}
                  numTicks={5}
                  stroke={theme.palette.text.secondary}
                  tickStroke={theme.palette.text.secondary}
                  hideTicks
                  tickFormat={(value) =>
                    formatYAxis(value, yMaxDomain, yMinDomain)
                  }
                  tickLabelProps={() => ({
                    fill: theme.palette.text.secondary,
                    fontSize: 10,
                    textAnchor: 'end',
                    dy: '0.33em',
                  })}
                />
                <AxisBottom
                  top={yMax}
                  scale={xScale}
                  numTicks={15}
                  stroke={theme.palette.text.secondary}
                  tickStroke={theme.palette.text.secondary}
                  tickTransform={`translate(${barWidth / 2}, 0)`}
                  tickFormat={formatXAxis}
                  tickLabelProps={() => ({
                    fill: theme.palette.text.secondary,
                    fontSize: 10,
                    textAnchor: 'middle',
                  })}
                />
                {data
                  .filter(({estimatedValue}) => estimatedValue !== null)
                  .map(({date, estimatedValue}, index) => (
                    <Bar
                      key={`bar-${index}`}
                      x={xScale(formatDate(date))}
                      y={yScale(estimatedValue)}
                      width={barWidth || 0}
                      height={yMax - yScale(estimatedValue) || 0}
                      fill={getChartColors(1)}
                      opacity={1}
                      rx={4}
                    />
                  ))}
                <LinePath
                  data={data.filter(
                    ({forecastedValue}) => forecastedValue !== null,
                  )}
                  curve={curveBasis}
                  x={xPosition}
                  y={({forecastedValue}) => yScale(forecastedValue)}
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  strokeOpacity={1}
                  strokeDasharray="2,4"
                />
                <LinePath
                  data={data.filter(({trendValue}) => trendValue !== null)}
                  curve={curveBasis}
                  x={xPosition}
                  y={({trendValue}) => yScale(trendValue)}
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  strokeOpacity={1}
                />
                <Threshold
                  id="threshold-top"
                  data={data}
                  x={xPosition}
                  y0={({ciUpper90}) => yScale(ciUpper90)}
                  y1={({ciUpper75}) => yScale(ciUpper75)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  curve={curveBasis}
                  belowAreaProps={{
                    fill: theme.palette.primary.dark,
                    fillOpacity: 0.5,
                  }}
                  aboveAreaProps={{
                    fill: theme.palette.primary.dark,
                    fillOpacity: 0.5,
                  }}
                />
                <Threshold
                  id="threshold-mid"
                  data={data}
                  x={xPosition}
                  y0={({ciUpper75}) => yScale(ciUpper75)}
                  y1={({ciLower75}) => yScale(ciLower75)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  curve={curveBasis}
                  belowAreaProps={{
                    fill: theme.palette.primary.main,
                    fillOpacity: 0.5,
                  }}
                  aboveAreaProps={{
                    fill: theme.palette.primary.main,
                    fillOpacity: 0.5,
                  }}
                />
                <Threshold
                  id="threshold-bottom"
                  data={data}
                  x={xPosition}
                  y0={({ciLower75}) => yScale(ciLower75)}
                  y1={({ciLower90}) => yScale(ciLower90)}
                  clipAboveTo={0}
                  clipBelowTo={yMax}
                  curve={curveBasis}
                  belowAreaProps={{
                    fill: theme.palette.primary.dark,
                    fillOpacity: 0.5,
                  }}
                  aboveAreaProps={{
                    fill: theme.palette.primary.dark,
                    fillOpacity: 0.5,
                  }}
                />
              </Group>
            </svg>
            {tooltipData && (
              <Tooltip
                top={tooltipTop}
                left={tooltipLeft}
                style={{
                  ...defaultStyles,
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.paper,
                  padding: 0,
                  margin: 0,
                  zIndex: 1500,
                  minWidth: '150px',
                }}>
                <Box
                  sx={{
                    paddingX: '16px',
                    paddingY: '8px',
                    fontSize: '12px',
                    borderRadius: '4px',
                  }}>
                  <Typography
                    variant="caption"
                    color={theme.palette.text.primary}
                    fontWeight="bold">
                    {moment(tooltipData.date).format('MMM YYYY')}
                  </Typography>
                  {Object.keys(tooltipData).map((key) => {
                    const {forecastedValue, trendValue} = tooltipData;
                    if (
                      !tooltipData[key] ||
                      key === 'date' ||
                      (key.includes('ci') &&
                        (!forecastedValue || trendValue === forecastedValue)) ||
                      (key === 'forecastedValue' &&
                        trendValue === forecastedValue)
                    )
                      return null;
                    const tooltipContent = tooltip.find(
                      (content) => content.value === key,
                    );
                    return (
                      <Box
                        key={key}
                        sx={{
                          display: 'flex',
                          marginTop: '4px',
                          alignItems: 'center',
                          fontWeight: 'normal',
                          color: theme.palette.text.secondary,
                          gap: 1,
                        }}>
                        <svg width={12} height={12}>
                          {key === 'forecastedValue' || key === 'trendValue' ? (
                            <line
                              x1="0"
                              y1="6"
                              x2="12"
                              y2="6"
                              stroke={tooltipContent.color}
                              strokeOpacity={1}
                              strokeWidth={2}
                              strokeDasharray={
                                key === 'forecastedValue' ? '3 1' : undefined
                              }
                            />
                          ) : (
                            <rect
                              fill={tooltipContent.color}
                              fillOpacity={1}
                              rx={4}
                              width={12}
                              height={12}
                            />
                          )}
                        </svg>
                        <Box>
                          <strong>{tooltipContent.label}: </strong>
                          {tooltipContent.formatValue
                            ? tooltipContent.formatValue(tooltipData[key])
                            : tooltipData[key]}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Tooltip>
            )}
          </>
        );
      }}
    </ParentSize>
  );
}

export default BaseThresholdChart;

BaseThresholdChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  marginBottom: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginTop: PropTypes.number,
  isLoading: PropTypes.bool,
  tooltip: PropTypes.array,
};
