import moment from 'moment-timezone';
import {DATE_TYPES} from '@/components/map/constants';

const toMoment = (date, timezone) =>
  timezone ? moment(date).tz(timezone) : moment(date);

const isYearlyDate = (type) => type === DATE_TYPES.YEARLY;

const isMonthlyDate = (type) => type === DATE_TYPES.MONTHLY;

const isQuarterlyDate = (type) => type === DATE_TYPES.QUARTERLY;

export {isYearlyDate, isMonthlyDate, toMoment, isQuarterlyDate};
