import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import Box from '@mui/material/Box';

import {base_languages as I18N} from '@languages';
import {
  filterToggleAllTimeslices,
  filterToggleTimeslice,
} from '@/store/modules/filters/actions';
import {getTimeSlices} from '@/selectors';
import BaseChip from '@/components/common/buttons/BaseChip';
import {useTranslation} from '@/hooks/useTranslation';
import FilterItem from '@/components/filters/FilterItem';
import BaseCheckbox from '@/components/common/checkboxes/BaseCheckbox';
import {styles} from '@/components/filters/Filters.styles';

function FilterTimeOfDay() {
  const dispatch = useDispatch();
  const {locale} = useTranslation();
  const timeSlices = useSelector(getTimeSlices);

  const allSelected = Object.values(timeSlices).every(Boolean);
  const someSelected = Object.values(timeSlices).some(Boolean);

  const toggleTimeSlice = (timeSlice) => {
    dispatch(filterToggleTimeslice(timeSlice));
  };

  const handleSelectAll = () => {
    dispatch(filterToggleAllTimeslices());
  };

  return (
    <FilterItem
      label={
        <BaseCheckbox
          bold
          label={I18N[locale].timeOfDay}
          checked={allSelected}
          indeterminate={someSelected && !allSelected}
          onClick={handleSelectAll}
        />
      }>
      <Box sx={styles.timeOfDayChip}>
        {Object.keys(timeSlices).map((key) => (
          <BaseChip
            key={key}
            label={I18N[locale].filters.timeslices[key]}
            selected={timeSlices[key]}
            onClick={() => toggleTimeSlice(key)}
          />
        ))}
      </Box>
    </FilterItem>
  );
}

export default FilterTimeOfDay;
