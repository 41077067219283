import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {useSentry} from '@hooks/useSentry';
import {useUserData} from '@hooks/api/useUserData';
import {setActiveOrganizationId} from '@/store/modules/organization/actions';
import {useOrganization} from '@/hooks/api/useOrganization';
import {useAuth0Service} from '@/hooks/useAuth0Service';
import {useTags} from '@/hooks/api/useTags';

export const useAuth0User = () => {
  const dispatch = useDispatch();
  const {user, isAuthenticated} = useAuth0Service();
  const {initializeSentry, setSentryUser, isInitialized} = useSentry();

  useUserData({enabled: isAuthenticated});
  useOrganization({enabled: isAuthenticated});
  useTags({enabled: isAuthenticated});

  useEffect(() => {
    if (user && isAuthenticated) {
      // set org
      dispatch(setActiveOrganizationId(user.org_id));
      // set sentry
      const isSentryInit = isInitialized();
      if (!isSentryInit) {
        initializeSentry();
        setSentryUser({email: user.email});
      }
    }
  }, [dispatch, isAuthenticated, user]);
};
