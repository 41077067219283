import image from '@images/forecast-teaser.png';

export const styles = {
  legend: {
    marginTop: 2,
  },
  select: {
    width: 340,
    height: 32,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'left',
    gap: 1,
  },
  forecastTeaser: {
    container: {
      height: '200px',
      width: '100%',
      backgroundImage: `url(${image})`,
    },
    content: {
      width: '300px',
      height: '110px',
      paddingTop: 2,
    },
    backdrop: {
      position: 'relative',
      width: 'inherit',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0)',
      backdropFilter: 'blur(5px)',
      WebkitBackdropFilter: 'blur(5px)',
    },
    root: {
      position: 'relative',
      width: 'inherit',
      height: 'inherit',
    },
  },
};
