import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {Typography} from '@mui/material';
import {styles} from '@/components/common/buttons/BaseChip.styles';

function BaseChip({
  selected,
  isAction,
  loading,
  disabled,
  label,
  size = 'medium',
  connected = false,
  position = 'middle',
  color = 'default',
  selectedColor = 'primary',
  sx,
  icon,
  iconPosition = 'right',
  ...props
}) {
  const icons = useMemo(() => {
    if (loading) {
      return <CircularProgress sx={styles.chipProgress} size={16} />;
    }
    if (icon) {
      return icon;
    }
    return undefined;
  }, []);

  return (
    <Chip
      sx={(theme) => ({
        ...styles.root,
        ...(selected ? {} : styles.outlined(theme)),
        ...(typeof sx === 'function' ? sx(theme) : sx),
      })}
      style={connected ? styles[`${position}Chip`] : {}}
      color={selected ? selectedColor : color}
      variant={selected ? 'default' : 'outlined'}
      disabled={loading || disabled}
      label={<Typography variant="caption">{label}</Typography>}
      size={size}
      icon={iconPosition === 'left' ? icons : undefined}
      deleteIcon={iconPosition === 'right' ? icons : undefined}
      onDelete={icon ? () => {} : undefined}
      {...props}
    />
  );
}

BaseChip.propTypes = {
  selected: PropTypes.bool,
  isAction: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  connected: PropTypes.bool,
  position: PropTypes.oneOf(['first', 'middle', 'last']),
  color: PropTypes.oneOf(['primary', 'default', 'info', 'secondary']),
  selectedColor: PropTypes.oneOf(['primary', 'default', 'info', 'secondary']),
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
};

export default memo(BaseChip);
