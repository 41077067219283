import {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useChartTheme} from '@/hooks/charts/useChartTheme';
import {useTranslation} from '@/hooks/useTranslation';
import BaseXYChart from '@/components/common/charts/BaseXYChart';
import BaseBar from '@/components/common/charts/XYCharts/BaseBar';

interface XYData {
  x: string;
  y: number;
}

function EventSummary({
  data = {chartData: []},
  isLoading = false,
  type = 'count',
  height,
  width,
}: {
  data: {
    chartData: Array<{
      categoryId: string;
      count: number;
    }>;
  } | null;
  isLoading: boolean;
  type: 'count' | 'percent';
  height: number;
  width: number;
}) {
  const {getChartColors} = useChartTheme();
  const {locale, getThreatTypesTranslation, getI18N} = useTranslation();
  const {threatCount, percent} = getI18N('chartSelection');

  const countData: Array<XYData> = useMemo(
    () =>
      data?.chartData?.map(({categoryId, count}) => ({
        x: getThreatTypesTranslation(categoryId),
        y: count,
      })) || [],
    [data, locale],
  );

  const percentData: Array<XYData> = useMemo(() => {
    if (!data?.chartData) {
      return [];
    }
    const total = data.chartData.reduce((acc, {count}) => acc + count, 0);

    return data.chartData.map(({categoryId, count}) => ({
      x: getThreatTypesTranslation(categoryId),
      y: Math.round((count / total) * 1000) / 10,
    }));
  }, [data, locale]);

  const formattedData: Array<XYData> = useMemo(() => {
    if (type === 'count') {
      return countData;
    }
    if (type === 'percent') {
      return percentData;
    }
    return [];
  }, [countData, percentData, type]);

  const tooltipLabel: string = useMemo(() => {
    if (type === 'count') {
      return threatCount;
    }
    if (type === 'percent') {
      return percent;
    }
  }, [type, locale]);

  const yMax: number = useMemo(
    () => Math.max(...formattedData.map((d) => d.y)),
    [formattedData],
  );

  return (
    <BaseXYChart
      isLoading={isLoading}
      height={height}
      width={width}
      customColors={getChartColors(1)}
      angledTicks
      hideGrid
      marginBottom={110}
      yScale={{
        type: 'linear',
        domain: [0, Math.max(5, yMax)],
      }}>
      <BaseBar data={{[tooltipLabel]: formattedData}} />
    </BaseXYChart>
  );
}

EventSummary.propTypes = {
  type: PropTypes.oneOf(['count', 'percent']),
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default EventSummary;
