import {useCallback} from 'react';
import {AnalyticsBrowser} from '@segment/analytics-next';
import {useSelector} from 'react-redux';
import {SENTRY_ENV, SEGMENT_WRITE_KEY, MODE} from '@config';
import {getOrganization} from '@/selectors';

// Define the shape of our analytics instance
type AnalyticsInstance = AnalyticsBrowser;

// Define types for the hook's return value
interface SegmentHook {
  group: (groupId: string, traits?: Record<string, unknown>) => void;
  page: (
    category?: string,
    name?: string,
    properties?: Record<string, unknown>,
  ) => void;
  identify: (userId: string, traits?: Record<string, unknown>) => void;
  track: (event: string, properties?: Record<string, unknown>) => void;
}

// Create a single instance of AnalyticsBrowser
const analytics: AnalyticsInstance = AnalyticsBrowser.load({
  writeKey: SEGMENT_WRITE_KEY,
});

// Helper function to check if we should send analytics
const shouldSend = (): boolean => MODE === 'production';

/**
 * A custom hook for Segment analytics calls using @segment/analytics-next.
 * @returns An object containing methods for various Segment calls.
 */
export const useSegmentAnalytics = (): SegmentHook => {
  const organization = useSelector(getOrganization);

  /**
   * Send a Group call to Segment.
   * @param groupId - The ID of the group.
   * @param traits - Optional traits associated with the group.
   */
  const group = useCallback(
    (groupId: string, traits?: Record<string, unknown>): void => {
      if (shouldSend()) analytics.group(groupId, traits);
    },
    [],
  );

  /**
   * Send a Page call to Segment.
   * @param category - Optional category of the page.
   * @param name - Optional name of the page.
   * @param properties - Optional additional properties for the page.
   */
  const page = useCallback(
    (
      category?: string,
      name?: string,
      properties?: Record<string, unknown>,
    ): void => {
      if (shouldSend())
        analytics.page(category, name, {
          ...properties,
          organization: organization.active,
          environment: SENTRY_ENV,
        });
    },
    [organization.active],
  );

  /**
   * Send an Identify call to Segment.
   * @param userId - The unique identifier for the user.
   * @param traits - Optional traits associated with the user.
   */
  const identify = useCallback(
    (userId: string, traits?: Record<string, unknown>): void => {
      if (shouldSend()) analytics.identify(userId, traits);
    },
    [],
  );

  /**
   * Send a Track call to Segment.
   * @param event - The name of the event to track.
   * @param properties - Optional properties associated with the event.
   */
  const track = useCallback(
    (event: string, properties?: Record<string, unknown>): void => {
      if (shouldSend())
        analytics.track(event, {
          ...properties,
          organization: organization.active,
          environment: SENTRY_ENV,
        });
    },
    [organization.active],
  );

  return {
    group,
    page,
    identify,
    track,
  };
};
