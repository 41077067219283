export const styles = {
  root: (theme) => ({
    flex: '1 1 auto',
    overflow: 'auto',
    marginBottom: 2,
    marginRight: 1,
    marginLeft: 1,
    borderRadius: 2,
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('sm')]: {
      marginRight: 2,
    },
  }),
  header: {
    marginLeft: 2,
    marginRight: 2,
    paddingTop: 1.5,
    paddingBottom: 1.5,
    fontWeight: 'bold',
  },
  boxChip: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'left',
    marginBlock: 1,
  },
  wrapperChip: {
    position: 'relative',
    margin: 0,
    padding: 0,
  },
  timeOfDayChip: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'left',
    gap: 1,
    marginBlock: 1,
  },
};
