import {useMemo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';

import {CaretDown, CaretRight} from '@phosphor-icons/react';

import {styles} from '@common/checkboxes/Base.styles';
import BaseCheckbox from '@/components/common/checkboxes/BaseCheckbox';

function BaseExpandableCheckboxGroup({
  bold = false,
  options = [],
  selected = [],
  onChange = () => {},
  defaultExpanded = false,
  columns,
  disabled,
  dense = false,
}) {
  const [expanded, setExpanded] = useState([]);

  const parentOptions = useMemo(() => {
    const parents = options.filter((option) => option.parentId === null);
    if (defaultExpanded) {
      setExpanded(parents.map((p) => p.id));
    }
    return parents;
  }, [options]);

  const getChildren = useCallback(
    (parentId) =>
      options.filter(
        (option) => option.parentId !== null && option.parentId === parentId,
      ) || [],
    [options],
  );

  const handleExpand = (id) => {
    if (expanded.includes(id)) {
      setExpanded(expanded.filter((expandedId) => expandedId !== id));
    } else {
      setExpanded([...expanded, id]);
    }
  };

  const isSelected = (id) => selected.includes(id);

  const isChecked = (parentId) => {
    if (parentOptions.some(({id}) => id === parentId)) {
      if (getChildren(parentId).length === 0) {
        return false;
      }
      return getChildren(parentId).every(({id}) => isSelected(id));
    }
    return isSelected(parentId);
  };

  const isIndeterminate = (parentId) => {
    if (parentOptions.some(({id}) => id === parentId)) {
      return (
        getChildren(parentId).some(({id}) => isSelected(id)) &&
        !getChildren(parentId).every(({id}) => isSelected(id))
      );
    }
    return false;
  };

  const handleSelect = (id) => {
    if (parentOptions.some((o) => o.id === id)) {
      const childrenIds = getChildren(id)
        .filter((o) => !o.disabled)
        .map((o) => o.id);
      if (isChecked(id) || isIndeterminate(id)) {
        // Remove all children from selected
        onChange(selected.filter((child) => !childrenIds.includes(child)));
      } else {
        // Add all children
        onChange([...selected, ...childrenIds]);
      }
    } else if (isChecked(id)) {
      onChange(selected.filter((child) => child !== id));
    } else {
      onChange([...selected, id]);
    }
  };

  return (
    <Box sx={styles.root}>
      {parentOptions.map(
        ({
          id: parentId,
          name: ParentName,
          disabled: parentDisabled,
          tooltip,
        }) => (
          <Box sx={styles.optionGroup} key={parentId}>
            <Box sx={styles.optionHeader}>
              {expanded.includes(parentId) ? (
                <Box sx={styles.expandIcon}>
                  <CaretDown size={18} onClick={() => handleExpand(parentId)} />
                </Box>
              ) : (
                <Box sx={styles.expandIcon}>
                  <CaretRight
                    size={18}
                    onClick={() => handleExpand(parentId)}
                  />
                </Box>
              )}
              <BaseCheckbox
                bold={bold}
                tooltip={tooltip}
                disabled={disabled ?? parentDisabled}
                label={ParentName}
                checked={isChecked(parentId)}
                indeterminate={isIndeterminate(parentId)}
                onClick={() => handleSelect(parentId)}
                dense={dense}
              />
            </Box>
            <Collapse in={expanded.includes(parentId)}>
              <Box sx={styles.collapsibleBox}>
                <Grid container>
                  {getChildren(parentId).map(
                    ({
                      name: childName,
                      id: childId,
                      disabled: childDisabled,
                      tooltip,
                    }) => (
                      <Grid
                        item
                        xs={columns ? 12 / columns : 'auto'}
                        sx={styles.gridItem}
                        key={childId}>
                        <BaseCheckbox
                          tooltip={tooltip}
                          disabled={disabled || childDisabled}
                          label={childName}
                          checked={isChecked(childId)}
                          onClick={() => handleSelect(childId)}
                          dense={dense}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              </Box>
            </Collapse>
          </Box>
        ),
      )}
    </Box>
  );
}

BaseExpandableCheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  defaultExpanded: PropTypes.bool,
  columns: PropTypes.number,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  dense: PropTypes.bool,
};

export default BaseExpandableCheckboxGroup;
