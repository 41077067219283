// Packages
import {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';

// State Handlers
import {getFilters, getIsGTMAvailable} from '@/selectors';

// Hooks
import {useTranslation} from '@/hooks/useTranslation';
import {useSegmentAnalytics} from '@/hooks/useSegment';

// Components
import FilterItem from '@/components/filters/FilterItem';
import BaseSwitch from '@/components/common/inputs/BaseSwitch';
import {setGTMStatus} from '@/store/modules/filters/actions';
import BaseTooltip from '@/components/common/BaseTooltip';
import {styles} from '@/components/filters/FilterGlobalThreatsModel.styles';

function FilterGlobalThreatsModel() {
  const dispatch = useDispatch();
  const {track} = useSegmentAnalytics();
  const {getI18N} = useTranslation();

  const {isGTM} = useSelector(getFilters);
  const isGTMAvailable = useSelector(getIsGTMAvailable);

  const {gtmSwitchLabel} = getI18N('filters');
  const {gtmAvailable, gtmUnavailable} = getI18N('tooltips');

  const handleGlobalThreatsModel = useCallback(() => {
    dispatch(setGTMStatus());
    track('GTM status', {status: isGTM});
  }, [dispatch, isGTM]);

  useEffect(() => {
    dispatch(setGTMStatus(isGTMAvailable));
  }, [dispatch, isGTMAvailable]);

  return (
    <FilterItem>
      <Box sx={styles.root}>
        <BaseSwitch
          checked={isGTM}
          disabled={!isGTMAvailable}
          onChange={handleGlobalThreatsModel}
          label={gtmSwitchLabel}
        />
        <BaseTooltip
          message={isGTMAvailable ? gtmAvailable : gtmUnavailable}
          iconSize={16}
        />
      </Box>
    </FilterItem>
  );
}

export default FilterGlobalThreatsModel;
