import {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {
  getNeighborhoodFeature,
  hasFeatureCountry,
  hasFeatures,
  getCountryFeature,
  hasCrimeMarkerFeatures,
  getCityMarkerFeatures,
  hasNeighborhoodFeature,
  hasNeighborhoodState,
  hasUserLocations,
  getUserLocations,
  hasThreatRadiusFeatures,
  getThreatRadiusFeatures,
  hasThreatScoreFeatures,
  getThreatScoreFeatures,
  getPopulationFeatures,
  hasPopulationFeatures,
} from '@utils/mapUtils';
import {useMapHandlers} from '@hooks/map/useMapHandlers';
import {debounce} from 'lodash';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {getLayers} from '@/selectors';
import {useTranslation} from '@/hooks/useTranslation';

interface MapEvent {
  latitude: number;
  longitude: number;
}

interface MapMouseEvent {
  features: unknown;
  event: MapEvent;
}

interface MapMouseMoveHook {
  handleMouseHover: (event: MapMouseEvent) => void;
}

export const useMapMouseMove = (): MapMouseMoveHook => {
  const {track} = useSegmentAnalytics();
  const {getI18N} = useTranslation();
  const {hideMapTooltip, showMapTooltip, highlightCountry, highlightRadius} =
    useMapHandlers();

  const {districts: isDistrictsLayer} = useSelector(getLayers);
  const {threatScore, population} = getI18N('tooltips');

  const handleMouseHover = useCallback(
    ({features, event: {latitude, longitude}}: MapMouseEvent) => {
      if (!hasFeatures(features)) {
        hideMapTooltip();
        highlightCountry();
        highlightRadius();
        return;
      }

      if (hasThreatRadiusFeatures(features)) {
        const {id} = getThreatRadiusFeatures(features);
        highlightRadius(id);
      }

      if (hasCrimeMarkerFeatures(features)) {
        const {properties, geometry} = getCityMarkerFeatures(features);
        showMapTooltip(
          properties.n,
          geometry.coordinates[1],
          geometry.coordinates[0],
        );
        return;
      }

      if (hasUserLocations(features)) {
        const {properties, geometry} = getUserLocations(features);
        showMapTooltip(
          properties.n,
          geometry.coordinates[1],
          geometry.coordinates[0],
        );
        debounce(() => {
          track('Hover User Saved Location Marker');
        }, 300);
        return;
      }

      if (
        hasNeighborhoodFeature(features) &&
        hasNeighborhoodState(features) &&
        isDistrictsLayer
      ) {
        const {properties} = getNeighborhoodFeature(features);
        showMapTooltip(properties.displayName, latitude, longitude);
        return;
      }

      if (hasFeatureCountry(features)) {
        const {id, properties} = getCountryFeature(features);
        highlightCountry(id);
        showMapTooltip(properties.name, latitude, longitude);
        return;
      }

      if (hasThreatScoreFeatures(features)) {
        const {properties} = getThreatScoreFeatures(features);
        showMapTooltip(
          `${threatScore}: ${properties.tsd}`,
          latitude,
          longitude,
        );
        return;
      }

      if (hasPopulationFeatures(features)) {
        const {properties} = getPopulationFeatures(features);
        showMapTooltip(`${population}: ${properties.p}`, latitude, longitude);
        return;
      }

      hideMapTooltip();
      highlightRadius();
      highlightCountry();
    },
    [hideMapTooltip, showMapTooltip, highlightCountry, isDistrictsLayer],
  );
  return {handleMouseHover};
};
