export const styles = {
  content: (theme) => ({
    backgroundColor: theme.palette.background.paper,
    height: 550,
    width: 600,
    maxWidth: 600,
    maxHeight: 550,
    gap: 4,
  }),
};
