export const getLocationFilterOptions = (locationFilterOptions) =>
  // Compute location filter options based
  Object.keys(locationFilterOptions).map((key) => ({
    id: key,
    name: locationFilterOptions[key],
    parentId: 'main',
  }));

export const getLocationsFilterTags = (savedLocations) => {
  const tags = savedLocations.reduce((acc, savedLocation) => {
    const objTags = savedLocation.tags || [];
    return [...acc, ...objTags];
  }, []);

  return Array.from(new Set(tags));
};

export const DEGREE_OF_CHANGE = [
  'large_increase',
  'increase',
  'no_change',
  'decrease',
  'large_decrease',
  // 'indeterminate',
];

export const SAVED_LOCATIONS_VISIBILITY = ['shared', 'private'];

export const SAVED_LOCATION_TYPES = {
  office: 'Office',
  hotel: 'Hotel',
  event: 'Event',
  restaurant: 'Restaurant',
  travel: 'Travel',
  warehouse: 'Warehouse',
  parking: 'Parking',
  service_area: 'Service Area',
  point_of_interest: 'Point of Interest',
  facility: 'Facility',
  other: 'Other',
  retail: 'Retail',
};

export const SAVED_LOCATION_SEARCH = {
  search: undefined,
  page: 1,
  take: 12,
  sortBy: `name.ASC`,
  addressTypes: [],
  tags: null,
  minCustomerRating: 1,
  maxCustomerRating: 5,
  includeUnrated: true,
  visibility: undefined,
};

export const CHANGE_SORT_METRICS = [
  {
    value: 'one_month_period_over_year',
    feature: 'saved-locations-change',
    action: 'label',
    label: 'Monthly Change YoY',
  },
  {
    value: 'year_over_year',
    feature: 'saved-locations-change',
    action: 'label',
    label: 'Year-over-year Change',
  },
];

export const CHANGE_MAP_METRICS = {
  yearOverYear: {
    monthly: 'c_m_o_y',
    quarterly: 'c_3m_o_y',
    yearly: 'c_y_o_y',
  },
  periodOverPeriod: {
    monthly: 'c_m_o_m',
    quarterly: 'c_3m_o_m',
    yearly: 'c_y_o_y',
  },
};

export const CHANGE_CHART_METRICS = {
  yearOverYear: {
    monthly: 'one_month_period_over_year',
    quarterly: 'three_month_period_over_year',
    yearly: 'year_over_year',
  },
  periodOverPeriod: {
    monthly: 'one_month_period_over_period',
    quarterly: 'three_month_period_over_period',
    yearly: 'year_over_year',
  },
};

export const SAVED_SORT_OPTIONS = [
  {
    value: 'change',
    feature: 'saved-locations-change',
    action: 'hide',
    tooltip: 'changeTooltip',
  },
  {value: 'name'},
  {value: 'customerLocationId'},
  {value: 'customerRating'},
];

export const getLocationDateRange = (startDate, endDate, separator = '-') => {
  if (!startDate || !endDate) return '';
  // Returns date range in format: "MM/YY - MM/YY"
  const startArr = startDate.split('-');
  const endArr = endDate.split('-');
  return `${startArr[1]}/${startArr[0].substring(2, 4)} ${separator} ${
    endArr[1]
  }/${endArr[0].substring(2, 4)}`;
};

export const chartIds = {
  changeDistribution: 'change-distribution-chart',
  breakdownByType: 'breakdown-by-location-type-chart',
  breakdownByTag: 'breakdown-by-location-tag-chart',
  changeByType: 'change-by-location-type-chart',
  changeByTag: 'change-by-location-tag-chart',
  rankedLocations: 'ranked-locations-table',
};
