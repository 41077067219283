// Packages
import {useEffect, useMemo, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// state
import {Camera, Trash} from '@phosphor-icons/react';
import {Input} from '@mui/material';
import {getUser} from '@/selectors';
import {setUserLanguage} from '@/store/modules/user/actions';

// Components
import BaseTextField from '@/components/common/inputs/BaseTextField';
import BaseSelect from '@/components/common/inputs/BaseSelect';
import BaseButton from '@/components/common/buttons/BaseButton';
import UserAvatar from '@/components/user/UserAvatar';

// Hooks
import {useTranslation} from '@/hooks/useTranslation';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useApi} from '@/hooks/api/useApi';

// Styles
import {styles} from '@/components/user/UserProfile.styles';
import {useMUIForm} from '@/hooks/useMUIForm';

const languages = [
  {label: 'English', value: 'en_US'},
  {label: 'Español', value: 'es_MX'},
  {label: 'Italiano', value: 'it_IT'},
  {label: 'Português', value: 'pt_BR'},
  {label: 'Français', value: 'fr_FR'},
];

function UserProfile() {
  const dispatch = useDispatch();
  const {track} = useSegmentAnalytics();
  const {locale, getI18N} = useTranslation();
  const {patchUser, postUserPhoto, patchUserSettings} = useApi();
  const inputFileRef = useRef(null);

  const user = useSelector(getUser);

  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [previewImg, setPreviewImg] = useState(null);
  const [removedPhoto, setRemovedPhoto] = useState(false);

  const {
    firstNameLabel,
    lastNameLabel,
    // emailLabel,
    languageLabel,
    unitsLabel,
    saveChanges,
    allChangesSaved,
  } = getI18N('user');
  const {addPhoto, deletePhoto, changePhoto} = getI18N('uploadPhotoModal');
  const {kilometers, miles} = getI18N('units');

  const unitsOptions = [
    {label: kilometers, value: 'km'},
    {label: miles, value: 'mi'},
  ];

  // Form Validation
  const validationSchema = Yup.object().shape({
    photo: Yup.string().optional().nullable(),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    language: Yup.string().required('Language is required'),
    units: Yup.string().required('Units is required'),
  });

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: {errors, isDirty},
    reset,
  } = useMUIForm(
    useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        photo: null,
        firstName: '',
        lastName: '',
        language: 'en_US',
        units: 'mi',
      },
    }),
  );

  const handleChangeLanguage = (language) => {
    if (language === locale) return;

    dispatch(setUserLanguage(language));
    track('Language change', {
      new_language: language,
      previous_language: locale,
    });
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      reset({
        photo: user.photo,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        language: user.language || 'en_US',
        units: user.units,
      });
    }
  }, [user]);

  const onSubmit = (data) => {
    patchUserSettings.mutate({
      body: {...user.settings, language: data.language, units: data.units},
    });
    if (selectedPhoto) {
      const formData = new FormData();
      formData.append('file', selectedPhoto);

      setSelectedPhoto(null);

      postUserPhoto.mutate(
        {body: formData},
        {
          onSuccess: (url) => {
            patchUser.mutate({body: {...data, photo: url}});
          },
        },
      );
    }
    setRemovedPhoto(false);
    handleChangeLanguage(data.language);
  };

  const handleChangePhoto = (event) => {
    setRemovedPhoto(false);
    if (!event.target.files?.length) return;

    const photo = event.target.files[0];

    setPreviewImg(URL.createObjectURL(photo));
    setSelectedPhoto(photo);
  };

  const handleDeletePhoto = () => {
    setRemovedPhoto(true);
    setPreviewImg(null);
    setValue('photo', null);
    setSelectedPhoto(null);
    if (inputFileRef.current?.value) inputFileRef.current.value = null;
    if (previewImg) URL.revokeObjectURL(previewImg);
  };

  const handleOpenFileDialog = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const canRemovePhoto = useMemo(
    () => (user?.photo?.length > 0 || previewImg) && !removedPhoto,
    [user, previewImg, removedPhoto],
  );

  const unsavedData = useMemo(
    () => isDirty || selectedPhoto || removedPhoto,
    [isDirty, selectedPhoto, removedPhoto],
  );

  return (
    <Box sx={styles.content}>
      <UserAvatar
        user={{...user, photo: !removedPhoto ? user?.photo : null}}
        size="6.25rem"
        imgPreview={previewImg}
        isEditable
      />
      <Box sx={styles.actionSection}>
        <Box sx={styles.icons} component="label" htmlFor="upload-profile-photo">
          <Box component="span">
            <BaseButton
              sx={styles.button}
              onClick={handleOpenFileDialog}
              variant="outlined"
              startIcon={<Camera size={18} weight="bold" />}>
              {canRemovePhoto ? changePhoto : addPhoto}
            </BaseButton>
          </Box>
          <Input
            inputRef={inputFileRef}
            sx={styles.inputFile}
            id="upload-profile-photo"
            name="upload-profile-photo"
            type="file"
            onChange={handleChangePhoto}
          />
        </Box>
        {canRemovePhoto && (
          <BaseButton
            sx={styles.button}
            onClick={handleDeletePhoto}
            variant="outlined"
            color="error"
            startIcon={<Trash size={18} weight="bold" />}>
            {deletePhoto}
          </BaseButton>
        )}
      </Box>
      <Grid container spacing={2} sx={styles.form}>
        <Grid item xs={12}>
          <BaseTextField
            fullWidth
            id="firstName"
            name="firstName"
            required
            label={firstNameLabel}
            variant="standard"
            size="medium"
            errors={errors.firstName}
            {...register('firstName')}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseTextField
            fullWidth
            id="lastName"
            name="lastName"
            required
            label={lastNameLabel}
            variant="standard"
            size="medium"
            errors={errors.lastName}
            {...register('lastName')}
          />
        </Grid>
        {/* Will be added back in later */}
        {/* <Grid item xs={12}>
          <BaseTextField
            fullWidth
            id="email"
            name="email"
            label={emailLabel}
            variant="standard"
            size="medium"
            value={user.email || ''}
            disabled
          />
        </Grid> */}
        <Grid item xs={6}>
          <BaseSelect
            control={control}
            fullWidth
            id="language"
            name="language"
            label={languageLabel}
            variant="standard"
            size="medium"
            errors={errors.addressType}
            options={languages.map(({label, value}) => ({
              label,
              value,
            }))}
          />
        </Grid>
        <Grid item xs={6}>
          <BaseSelect
            control={control}
            fullWidth
            id="units"
            name="units"
            label={unitsLabel}
            variant="standard"
            size="medium"
            errors={errors.addressType}
            options={unitsOptions.map(({label, value}) => ({
              label,
              value,
            }))}
          />
        </Grid>
      </Grid>
      <Box sx={styles.buttonContainer}>
        <Box sx={styles.grow} />
        <BaseButton
          variant="contained"
          disabled={!unsavedData}
          onClick={handleSubmit(onSubmit)}
          sx={styles.ml1}>
          {unsavedData ? saveChanges : allChangesSaved}
        </BaseButton>
      </Box>
    </Box>
  );
}

export default UserProfile;
