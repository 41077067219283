import React, {useEffect, useState} from 'react';

// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {API_KEY} from '@hooks/api/constants';
import {styles} from '@/components/user/key-management/UserKeyManagement.styles';

// Components
import BaseButton from '@/components/common/buttons/BaseButton';
import KeyManagementGrid from '@/components/user/key-management/KeyManagementGrid';
import KeyManagementModal from '@/components/user/key-management/KeyManagementModal';

// hooks
import {useMoment} from '@/hooks/useMoment';
import {useTranslation} from '@/hooks/useTranslation';
import {useApi} from '@/hooks/api/useApi';
import {useSegmentAnalytics} from '@/hooks/useSegment';
import {useSnackbar} from '@/hooks/useSnackbar';

function UserKeyManagement() {
  const {useGetQuery, postAPIKey, deleteAPIKey} = useApi();
  const {track} = useSegmentAnalytics();
  const {showSnackbar, hideSnackbar} = useSnackbar();
  const {formatAPIkeyDate} = useMoment();
  const {getI18N} = useTranslation();

  const {
    createTitle,
    deleteAction,
    deleteTitle,
    copyTitle,
    createFeedback,
    deleteFeedback,
    copyFeedback,
    createButton,
    nameLabel,
    secretLabel,
    dateLabel,
    actionsLabel,
  } = getI18N('user');

  const [isMounted, setIsMounted] = useState(false);
  const [apiKey, setApiKey] = useState({});
  const [apiKeys, setApiKeys] = useState([]);
  const [modal, setModal] = useState({
    title: createTitle,
    isOpen: false,
    status: 'create',
  });

  const {
    data: apiKeysData,
    isFetching: isFetchingAPIKeys,
    refetch: refetchAPIkeys,
  } = useGetQuery({
    path: API_KEY,
    config: {
      enabled: isMounted,
    },
  });

  useEffect(() => {
    if (apiKeysData?.length >= 0 && !isFetchingAPIKeys) {
      setApiKeys(
        apiKeysData.map((key) => ({
          ...key,
          secret: `bo_${key.id}${'*'.repeat(33)}`,
          createdAt: formatAPIkeyDate(key.createdAt),
          actions: [
            {
              label: deleteAction,
              handler: () => {
                setApiKey(key);
                setModal({
                  isOpen: true,
                  title: deleteTitle,
                  status: 'delete',
                });
              },
            },
          ],
        })),
      );
    }
  }, [apiKeysData, isFetchingAPIKeys]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleCreate = (data) =>
    postAPIKey.mutate(
      {params: data},
      {
        onSuccess: (key) => {
          refetchAPIkeys();
          setApiKey({...data, secret: key});
          setModal((state) => ({
            ...state,
            title: copyTitle,
            status: 'copy',
          }));
          track('Created User API key', {apiKey: data});
          showSnackbar({
            iconColor: 'success',
            icon: 'check',
            message: createFeedback,
          });
        },
      },
    );

  const handleClose = () => {
    hideSnackbar();
    setApiKey({});
    setModal({
      title: createTitle,
      isOpen: false,
      status: 'create',
    });
  };

  const handleDelete = () =>
    deleteAPIKey.mutate(
      {query: apiKey?.id},
      {
        onSuccess: () => {
          refetchAPIkeys();
          handleClose();
          track('Deleted User API key', {apiKey});
          showSnackbar({
            iconColor: 'success',
            icon: 'check',
            message: deleteFeedback,
          });
        },
      },
    );

  const handleCopy = () => {
    track('Copied User API Key to clipboard');
    showSnackbar({
      iconColor: 'success',
      icon: 'check',
      message: copyFeedback,
    });
  };

  const handleSubmit = {
    create: handleCreate,
    copy: handleCopy,
    delete: handleDelete,
  };

  const handleOpen = () => setModal((state) => ({...state, isOpen: true}));

  const headers = [
    {key: 'name', label: nameLabel, xs: 2},
    {key: 'secret', label: secretLabel, xs: 4},
    {key: 'createdAt', label: dateLabel, xs: 2},
    {key: 'actions', label: actionsLabel, xs: 4},
  ];

  return (
    <Container maxWidth="xl">
      <Box sx={styles.root}>
        <Box sx={styles.buttonContainer}>
          <BaseButton onClick={handleOpen}>{createButton}</BaseButton>
        </Box>
        <KeyManagementGrid data={apiKeys} headers={headers} />
        <KeyManagementModal
          apiKey={apiKey}
          title={modal.title}
          open={modal.isOpen}
          status={modal.status}
          onSubmit={handleSubmit[modal.status]}
          handleClose={handleClose}
        />
      </Box>
    </Container>
  );
}

export default UserKeyManagement;
