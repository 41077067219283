import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/montserrat/600.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import App from '@/App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
