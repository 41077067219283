import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';

import {API_ORGANIZATION} from '@hooks/api/constants';
import {useApi} from '@hooks/api/useApi';
import {
  setActiveOrganization,
  setOrganizations,
} from '@/store/modules/organization/actions';
import {getOrganization} from '@/selectors';
import {useSegmentAnalytics} from '@/hooks/useSegment';

export const useOrganization = ({enabled}) => {
  const {activeId: activeOrgId} = useSelector(getOrganization);
  const segment = useSegmentAnalytics();
  const dispatch = useDispatch();
  const {useGetQuery} = useApi();

  const {data, isFetching} = useGetQuery({
    path: API_ORGANIZATION,
    config: {
      enabled: enabled && Boolean(activeOrgId),
    },
  });

  useEffect(() => {
    if (data?.length > 0 && !isFetching) {
      dispatch(setOrganizations(data));
    }
  }, [dispatch, data, isFetching]);

  useEffect(() => {
    if (data?.length > 0 && activeOrgId) {
      const organization = data.find(({id}) => id === activeOrgId);
      dispatch(setActiveOrganization(organization));
      segment.group(organization.id, organization);
    }
  }, [dispatch, data, activeOrgId]);
};
