// Packages
import React, {
  useEffect,
  useRef,
  useState,
  memo,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

// Styles

// MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

// Hooks
import {useTranslation} from '@hooks/useTranslation';
import {styles} from '@/components/saved-locations/cards/SavedLocationCard.styles';
import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import BaseChip from '@/components/common/buttons/BaseChip';

const LINES_GRID_VIEW = 1;
const LINES_LIST_VIEW = 1;
const GAP = 8;
const WIDTH_FOR_REMAINING_TAG = 34 + GAP * 2;

function SavedLocationTags({
  tags = [],
  view = 'grid',
  featuredTags = [],
  featureName = '',
}) {
  const {getI18N, getSavedTranslations} = useTranslation();
  const chipContainerRef = useRef(null);
  const resizeObserverRef = useRef(null);
  const [tagsWithDetails, setTagsWithDetails] = useState([]);
  const [maxTagsToShow, setMaxTagsToShow] = useState(undefined);
  const [expanded, setExpanded] = useState(false);
  const [anchorPopover, setAnchorPopover] = useState(null);

  const {additionalTags} = getI18N('savedLocations');

  const allTags = useMemo(
    () =>
      [...tags, ...featuredTags].map((tag) => ({
        name: tag,
        feature: featuredTags.some((featuredTag) => featuredTag === tag)
          ? featureName
          : '',
      })),
    [featuredTags, tags],
  );

  const remainingTags = useMemo(
    () => allTags.length - maxTagsToShow,
    [maxTagsToShow, allTags],
  );

  const handleExpand = () => {
    setAnchorPopover(chipContainerRef.current);
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
    setAnchorPopover(null);
  };

  const handleResize = (observeEntries) => {
    if (!tagsWithDetails?.length) return setMaxTagsToShow(undefined);

    const observeEntry = observeEntries[0];
    const {width} = observeEntry.contentRect;

    let remainingWidth =
      view === 'list' ? width * LINES_LIST_VIEW : width * LINES_GRID_VIEW;

    let maxTags = 0;

    tagsWithDetails.forEach((tag) => {
      remainingWidth -= tag.width + GAP;

      if (remainingWidth <= WIDTH_FOR_REMAINING_TAG) return;

      maxTags += 1;
    });

    setMaxTagsToShow(maxTags);
  };

  const getTagsDetails = useCallback(() => {
    if (!chipContainerRef.current) return tagsWithDetails;

    const chips = Object.values(chipContainerRef.current.children);

    const chipsWithTags = chips.filter((chip) =>
      allTags.find(
        (tag) => tag.name.toLowerCase() === chip.innerText.toLowerCase(),
      ),
    );

    const newTagsWithDetails = chipsWithTags.map((chip) => {
      const tag = allTags.find(
        (tag) => tag.name.toLowerCase() === chip.innerText.toLowerCase(),
      );
      const {offsetWidth} = chip;

      return {
        width: offsetWidth,
        ...tag,
      };
    });

    return newTagsWithDetails;
  }, [maxTagsToShow]);

  useEffect(() => {
    if (chipContainerRef.current) {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        setMaxTagsToShow(undefined);
        resizeObserverRef.current = null;
      }

      resizeObserverRef.current = new ResizeObserver((observeEntries) =>
        handleResize(observeEntries),
      );

      resizeObserverRef.current.observe(chipContainerRef.current);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
        setMaxTagsToShow(undefined);
      }
    };
  }, [allTags, tagsWithDetails]);

  useEffect(() => {
    if (maxTagsToShow === undefined) {
      setTagsWithDetails(getTagsDetails());
    }
  }, [allTags]);

  if (allTags.length === 0) return null;

  return (
    <Box ref={chipContainerRef} sx={styles.chipContainer}>
      {allTags
        .slice(0, resizeObserverRef.current ? maxTagsToShow : undefined)
        .map(({name, feature}, index) => (
          <FeatureAccessWrapper feature={feature} key={`tag-${name}-${index}`}>
            <BaseChip
              key={`tag-${name}-${index}`}
              selected
              color="secondary"
              size="small"
              sx={{
                ...styles.chip,
                ...((!resizeObserverRef.current ||
                  maxTagsToShow === undefined) &&
                  styles.chip.hidden),
              }}
              label={
                getSavedTranslations(
                  name.charAt(0).toUpperCase() + name.slice(1),
                ) || name
              }
            />
          </FeatureAccessWrapper>
        ))}
      {maxTagsToShow !== undefined && remainingTags > 0 && (
        <BaseChip
          key="remaining-tags"
          color="secondary"
          size="small"
          sx={{
            ...styles.remainingChips,
            ...(expanded && styles.chip.expanded),
          }}
          label={`+ ${remainingTags}`}
          onClick={expanded ? handleCollapse : handleExpand}
          clickable
          selected
        />
      )}
      <Popover
        open={expanded}
        onClose={handleCollapse}
        anchorEl={anchorPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={(theme) =>
          styles.popover(theme, chipContainerRef.current?.clientWidth)
        }>
        <Typography variant="subtitle2" sx={styles.cardHeaderTitle}>
          {additionalTags}
        </Typography>
        {allTags.slice(maxTagsToShow).map(({name, feature}, index) => (
          <FeatureAccessWrapper key={`tag-${name}-${index}`} feature={feature}>
            <BaseChip
              selected
              color="secondary"
              label={name}
              sx={styles.chip.popover}
            />
          </FeatureAccessWrapper>
        ))}
      </Popover>
    </Box>
  );
}

SavedLocationTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  view: PropTypes.string,
  featuredTags: PropTypes.arrayOf(PropTypes.string),
  featureName: PropTypes.string,
};

export default memo(SavedLocationTags);
