import moment from 'moment';
import 'moment-timezone';
import {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {
  getTimezone,
  getFromDate,
  getToDate,
  getPrevFromDate,
  getPrevToDate,
  getIsMonthlyData,
  getIsYearlyData,
} from '@/selectors';
import {useTranslation} from '@/hooks/useTranslation';

export const useMoment = () => {
  const {locale} = useTranslation();
  const timezone = useSelector(getTimezone);
  const fromDate = useSelector(getFromDate);
  const toDate = useSelector(getToDate);
  const prevFromDate = useSelector(getPrevFromDate);
  const prevToDate = useSelector(getPrevToDate);
  const isMonthlyData = useSelector(getIsMonthlyData);
  const isYearlyData = useSelector(getIsYearlyData);

  moment.locale(locale);

  const toMoment = (date) =>
    timezone ? moment(date).tz(timezone) : moment(date);

  const didFilterDatesChanged = useCallback(() => {
    let format;
    if (isYearlyData) {
      format = 'YYYY';
    } else if (isMonthlyData) {
      format = 'MM-YYYY';
    } else {
      format = 'DD-MM-YYYY';
    }

    return (
      fromDate.clone().format(format) !== prevFromDate.clone().format(format) ||
      toDate.clone().format(format) !== prevToDate.clone().format(format)
    );
  }, [isYearlyData, isMonthlyData, fromDate, prevFromDate, toDate, prevToDate]);

  const formatDetailsDate = (date, threatType) => {
    let dateFormat;

    if (threatType === 'crime') {
      dateFormat = 'DD MMM YYYY HH:mm';
    } else {
      dateFormat = 'DD MMM YYYY';
    }

    return toMoment(date).format(dateFormat);
  };

  const getISOfromDate = useCallback(
    () => fromDate.clone().toISOString(),
    [fromDate],
  );

  const getISOtoDate = useCallback(
    () => toDate.clone().toISOString(),
    [toDate],
  );

  /**
   * Get date to fetch notable events from (Up to 3 months before toDate)
   * @returns {string} ISO date
   */
  const getNotableEventsFromDate = () => {
    const diff = toDate.diff(fromDate, 'months');
    if (diff >= 3) {
      return toDate.clone().subtract(3, 'months').toISOString();
    }
    return fromDate.toISOString();
  };

  /**
   * Get date to fetch analytics from (Start of the month of fromDate)
   * If fromDate is within the last 3 days of the month, return start of next month
   * Otherwise return start of fromDate month
   * @returns {string} ISO date
   */
  const getAnalyticsStartDate = useCallback(() => {
    let date = fromDate.clone().startOf('month');
    const endOfMonth = fromDate.clone().endOf('month');

    if (
      toDate.diff(fromDate, 'months') >= 1 &&
      endOfMonth.diff(fromDate, 'hours') <= 72
    ) {
      date = fromDate.clone().add(1, 'month').startOf('month');
    }

    return date.toISOString();
  }, [fromDate, toDate]);

  /**
   * Get date to fetch analytics to (End of the month of toDate)
   * If toDate is within the last 3 days of the month, return end of toDate month
   * Otherwise return end of previous month
   * @returns {string} ISO date
   * */
  const getAnalyticsEndDate = useCallback(() => {
    let date = toDate.clone().endOf('month');

    if (
      toDate.diff(fromDate, 'months') >= 1 &&
      date.diff(toDate, 'hours') > 72
    ) {
      date = date.clone().subtract(1, 'month').endOf('month');
    }

    return date.toISOString();
  }, [fromDate, toDate]);

  const formatAnalyticsMonthlyDate = (date) =>
    moment(date).utc().format('MM/YYYY');

  const formatAnalyticsDefaultDate = (date) => moment(date).utc().format('L');

  const getMetricsStartDate = () =>
    Math.floor(fromDate.clone().utcOffset(0, true).valueOf() / 1000);

  const getMetricsEndDate = () =>
    Math.floor(toDate.clone().utcOffset(0, true).valueOf() / 1000);

  const formatAPIkeyDate = (date) => toMoment(date).format('L');

  const analyticsDifferenceInMonths = useMemo(
    () => moment(getAnalyticsEndDate()).diff(getAnalyticsStartDate(), 'months'),
    [getAnalyticsEndDate, getAnalyticsStartDate],
  );

  const getMonthAbbreviation = (date) => moment(date).format('MMM');

  return {
    moment,
    getAnalyticsEndDate,
    getAnalyticsStartDate,
    formatAnalyticsMonthlyDate,
    formatAnalyticsDefaultDate,
    toMoment,
    formatDetailsDate,
    getMetricsStartDate,
    getMetricsEndDate,
    didFilterDatesChanged,
    getISOfromDate,
    getNotableEventsFromDate,
    getISOtoDate,
    formatAPIkeyDate,
    analyticsDifferenceInMonths,
    getMonthAbbreviation,
  };
};
