import FeatureAccessWrapper from '@/components/feature-access/FeatureAccessWrapper';
import FeatureAccessChangeModal from '@/components/feature-access/FeatureAccessChangeModal';
import {styles} from '@/components/analytics/sections/TeaserBannerSection.styles';

function TeaserBannerSection() {
  return (
    <FeatureAccessWrapper
      feature="threat-analytics-change"
      action="tease"
      modal={{
        content: FeatureAccessChangeModal,
        sx: {content: styles.content},
        header: {},
      }}
    />
  );
}

export default TeaserBannerSection;
